<div class="main-wrapper">

    <mat-toolbar class="d-flex justify-content-between bg-white">
        <div class="pl-3 pr-3" routerLink="home">
           <img src="../../assets/Intell-Group-Logo-Grey.png" class="logo"/>
        </div>
        <div class="spacer"></div>
        <div class="desktop">
            <button mat-button routerLink="home" routerLinkActive="active">
                Home
            </button>
            <!-- <button mat-button routerLink="about" routerLinkActive="active">
                About
            </button> -->
            <button mat-button routerLink="courses" routerLinkActive="active">
                Courses
            </button>
            <!-- <button mat-button routerLink="contact" routerLinkActive="active">
                Contact us
            </button> -->
        </div>
        <div class="desktop">
        <button color="primary" mat-button (click)="Login()">Login</button>
        </div>
        <div class="mobile">
            <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">MENU</button>
            <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="home" routerLinkActive="active">
                        Home
                    </button>
                <!-- <button mat-menu-item routerLink="about" routerLinkActive="active">
                    About
                </button> -->
                <button mat-menu-item routerLink="courses" routerLinkActive="active">
                    Courses
                </button>
                <!-- <button mat-menu-item routerLink="contact" routerLinkActive="active">
                    Contact us
                </button> -->
                <button mat-menu-item (click)="Login()">Login</button>
            </mat-menu>
        </div>

    </mat-toolbar>
    <div class="p-0 dashboard-container flex-1 scrollable">
        <router-outlet></router-outlet>
    </div>
    <div class="row bg-dark text-white p-4">
        <div class="col-md-4">
        <a href="https://intellgroup.co.za/">
        <img  src="../../assets/Intell-Group-Logo-Grey.png" class="img img-fluid" alt=""></a>
    </div>
    <div class="col-md-6">
        <div class="row">
        <div class="col-md-4">
            <h4 class="elementor-heading-title elementor-size-default">Contact Details</h4>
            <a href="https://intellgroup.co.za/">
                <span class="elementor-icon-list-text">Intellgroup.co.za</span>
            </a><br>
            <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink" href="https://intellgroup.co.za/wp-content/uploads/2024/04/Intell-Company-Profile.pdf">
                <span class="elementor-button-content-wrapper">
                <span class="elementor-button-text">Download Company Profile</span>
                </span>
                </a>
        </div>
        <div class="col-md-4">
        <h4 class="elementor-heading-title elementor-size-default">Megan Ann Botha</h4>
        <a href="tel:+27712790181">
        <span class="elementor-icon-list-text">071 279 0181</span>
        </a><br/>
        <a href="mailto:megan@intellgroup.co.za">
        <span class="elementor-icon-list-text">megan&#64;intellgroup.co.za</span>
        </a>
        </div>
        <div class="col-md-4">
        <h4 class="elementor-heading-title elementor-size-default">Brandon Nepgen</h4>
        <a href="tel:+27799155578">
        <span class="elementor-icon-list-text">079 915 5578</span>
        </a>
        <br/>
        <a href="mailto:brandon@intellgroup.co.za">
        <span class="elementor-icon-list-text">brandon&#64;intellgroup.co.za</span></a>
        </div>
    </div>
    </div>
</div>

</div>


