
<div *ngIf="notifications">
    <div class="p-3 d-flex align-items-center">
        <h4 [matBadge]="notifications.size" matBadgeSize="medium" matBadgeOverlap="false">Notifications  </h4>
    </div>
    <div *ngFor="let n of notifications" class="d-flex flex-1 link justify-content-start m-2 align-items-center">
        <div class="flex-1 p-2 border-radius-5 eventColor7">
           <p>
            {{n.get('title')}}
            </p>
            <p>
                {{n.get('detail')}}
            </p>
            <p>
                {{n.get('updatedAt') | date: 'dd-MMM-yy, hh:mm'}}
            </p>
        </div>
      </div> 
</div>
