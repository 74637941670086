export enum ParseClasses {
    COURSES = 'Courses',
    APPOINTMENTS = 'Appointments',
    UPLOADS = 'Uploads',
    GROUPS = 'Groups',
    USER = "_User",
    NOTIFICATIONS = "Notifications",
    COURSEQUESTIONS = "Questions",
    ORDERS = "Orders",
    ENROLLMENTS = "Enrollments"
}
