import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PublicComponent } from './public.component';
import { CoursesComponent } from './courses/courses.component';
import { ViewCourseComponent } from './view-course/view-course.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { AccountComponent } from '../admin/account/account.component';
import { CourseProfileComponent } from '../shared/course-profile/course-profile.component';
import { GdriveComponent } from '../shared/gdrive/gdrive.component';


const routes: Routes = [
    { redirectTo: 'home', pathMatch: 'full', path: '' },
    {
        path: '',
        component: PublicComponent,
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'courses', component: CoursesComponent },
            { path: 'about', component: AboutComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'course', component: ViewCourseComponent },
            { path: 'course/:id', component: ViewCourseComponent}
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes), ],
    exports: [RouterModule]
})
export class AdminRoutingModule {}
