import { DataService } from 'src/app/services/data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    private data: DataService,
    @Inject(MAT_DIALOG_DATA)
    public _data: any
  ) {}

  delete() {
    // this.data.deleteContract(this._data.ID);
    this.dialogRef.close(this._data.ID);
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
