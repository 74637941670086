<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="main-wrapper">
  <div class="banner">
      <div class="row w-100 d-flex justify-content-center">
        <div class="col-md-8 pt-5">
          <!-- <h1 class="text-left mat-display-2">
            We Specialize In</h1>
          <ul class="mat-display-1">
            <li>Accredited Occupational </li>
            <li>Health and  Safety TrainingOn-Site and </li>
            <li>Off-Site Medicals Personal Protection Equipment</li>
          </ul> -->
          <p class="text-white mat-display-1">
        A diversified Health & Safety solutions provider focused on streamlining onboarding, enhancing
        safety learning and managing HSE compliance for Corporates, Contractors and their employees.

          </p>
          <div class="d-flex flex-wrap p-2">
            <button routerLink="/courses" class="m-3" mat-raised-button color="white"><mat-icon class="mr-3">note</mat-icon>Explore courses</button>
              <a class="m-3" href="https://intellgroup.co.za/contact/" target="_blank" mat-raised-button color="primary"><mat-icon class="mr-3">phone</mat-icon>Contact us</a>
          </div>
        </div>
      </div>
  </div>

</div>
