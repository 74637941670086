import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCamera, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { StudentService } from 'src/app/admin/services/student.service';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Parent } from 'backend/interfaces/parent.interface';
import { CourseService } from 'src/app/admin/services/course.service';

interface DialogData {
  title: string;
  result: boolean;
}

@Component({
  selector: 'app-asset-upload-dialog',
  templateUrl: './asset-upload-dialog.component.html',
  styleUrls: ['./asset-upload-dialog.component.scss']
})
export class AssetUploadDialogComponent implements OnInit {

  title;
  question;
  webcamImage: WebcamImage = null;
  uploadedImage: {name: string, source: string | ArrayBuffer, type?: string};
  showCamera = false;
  faCamera = faCamera;
  faUpload = faUpload;
  faUser = faUser;
  saving = false;
  recognitionImage: {name: string, source: string | ArrayBuffer, type?: string};
  parent: Parent;
  chartData = 10;
  @ViewChild('webcamImageDisplay') webcamImageDisplay: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dataInput') dataInput: ElementRef;
  // @ViewChild('recognitionFileInput') recognitionFileInput: ElementRef;
  constructor(public dialogRef: MatDialogRef<AssetUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private courseService: CourseService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.title = this.data.title;
    // this.courseService.activatedCourse$.subscribe(res => this.parent= res);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showThumb(result: {image: WebcamImage, detection: any}): void {
    this.webcamImage = result.image;
    if(this.webcamImageDisplay)
      this.webcamImageDisplay.nativeElement.src = result.image.imageAsDataUrl;
    this.recognitionImage = {
      source: result.image.imageAsBase64,
      name: this.parent.Name +'_'+this.parent.Surname,
      type: 'image/jpeg',
    }
    this.uploadedImage = {
      source: result.image.imageAsDataUrl,
      name: this.parent.Name +'_'+this.parent.Surname,
      type: 'image/jpeg',
    }
    this.toggleCamera();
  }

  async onFileSelected(event): Promise<void>{

    let reader = new FileReader();

    this.webcamImage = null;
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.webcamImageDisplay.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name,
            type: file.type,
          }
        }
      };
    }
  }

  toggleCamera(){
    this.showCamera = !this.showCamera;
  }

  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }


  async saveProfilePicture(): Promise<void>{
    const imageToUpload = this.uploadedImage;
    this.saving = true;
      this.courseService.uploadFile('1glguzAjhR7LzckleW_O5hjWdgrjAHJpn', imageToUpload, '').then(res => {
        this.dialogRef.close(res);
        this.openSnackBar("Asset successfuly saved.");
        this.saving = false;
      }).catch(err => {
        console.log(err);
      });
  }


  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
  
}
