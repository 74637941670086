
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading || saving">
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<h2 *ngIf="course">{{ course.Name }}</h2>

<!-- <ng-container *ngIf="questions.length > 0">
    <app-course-question *ngFor="let q of questions" [question]="q"></app-course-question>
</ng-container> -->

<form *ngIf="questionsForm" [formGroup]="questionsForm" class="mb-3">
  <div formArrayName="questions">
    <div class="border-primary p-3 m-3"
      *ngFor="let control of questionsFieldAsFormArray?.controls; let i = index" [formGroupName]="i"
    >
      <!-- {{i+1}}<input formControlName="i" type="text"> -->
      <div class="d-flex justify-content-between">
        <mat-label> Question {{ i + 1 }} </mat-label>
          <fa-icon class="link" (click)="remove(i)" style="color: red" [icon]="faRemove"></fa-icon>
      </div>
      <!-- <app-course-question
        [question]="control"
        [formGroupName]="i"
        (updateTestForm)="updateQuestion($event, i)"
      ></app-course-question> -->
      <div class="d-flex flex-column">
      <mat-form-field>
        <mat-label>
            Title
        </mat-label>
        <input matInput name="title" formControlName="title">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Body
        </mat-label>
        <input matInput name="body" formControlName="body">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Duration
        </mat-label>
        <input matInput name="duration" formControlName="duration">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Points
        </mat-label>
        <input matInput name="points" formControlName="points">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Note
        </mat-label>
        <input matInput name="note" formControlName="note">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Answer Type</mat-label>
        <mat-select (selectionChange)="setQuestionType(i, $event)" formControlName="answerType">
            <mat-option *ngFor="let type of answerTypes" [value]="type.text" >
              {{type.text}}
            </mat-option>
          </mat-select>
    </mat-form-field>
    <div *ngIf="control.controls['answerType'].value === 'Select'">
        <div *ngFor="let option of control.get('options').value;index as j">
          <mat-form-field>
            <mat-label>
                Option {{j+1}}
            </mat-label>
          <input matInput (change)="updateQuestionOptions($event, i, j)" [value]="option"/>
          </mat-form-field>
          <fa-icon class="link text-danger" (click)="removeQuestionOptions(i, j)" [icon]="faRemove"></fa-icon>
        </div>
      <button mat-mini-fab color="default" (click)="addOption(i)">
        <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
  <div *ngIf="control.controls['answerType'].value === 'List'" class="d-flex flex-column">
  <mat-form-field>
    <mat-label>
        Number of list items
    </mat-label>
    <input matInput type="number" min="1" required name="listCount" formControlName="listCount">
</mat-form-field>
<mat-radio-group aria-label="Numbered" formControlName="listLabelType">
  <mat-radio-button class="mr-3" value="none">No label</mat-radio-button>
  <mat-radio-button class="mr-3" value="numbers">Numbers</mat-radio-button>
  <mat-radio-button class="mr-3" value="letters">Letters</mat-radio-button>
</mat-radio-group>
</div>
<div class="d-flex justify-content-between">
  <img *ngIf="control.controls['imageId'].value" [src]="'https://drive.google.com/thumbnail?id='+control.controls['imageId'].value">
  <div *ngIf="control.controls['imageId'].value" class="initials link" (click)="removeImage(i)">
    <fa-icon [icon]="faTrash" class="text-danger"></fa-icon> 
  </div>
  <div class="initials text-white bg-primary link" (click)="openAssetUploadDialog(i)">
    <fa-icon [icon]="faImage" class="text-white"></fa-icon> 
  </div>
</div>

</div>
    </div>
  </div>
  <mat-toolbar class="mt-3 d-flex justify-content-between">
    <button mat-mini-fab color="primary" (click)="addQuestion()">
        <fa-icon [icon]="faPlus"></fa-icon>
    </button>
    <button (click)="save()" type="button" [disabled]="!questionsForm.valid || saving" mat-raised-button color="primary">
      Save
    </button>
  </mat-toolbar>
</form>

