
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
   <mat-stepper #stepper
    [orientation]="(stepperOrientation | async)!">
    <mat-step state="done" *ngIf="profileForm" [completed]="profileForm?.valid" [stepControl]="profileForm" label="Course Detail">
      <mat-card class="m-3">
          <mat-card-content>
              <form class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                  <div class="d-flex row p-3">
                <div class="col-md-6 d-flex flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Course name</mat-label>
                        <input matInput placeholder="Name" name="name" formControlName="Name">
                        <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Registration Number</mat-label>
                        <input matInput placeholder="RegistrationNumber" formControlName="RegistrationNumber">
                        <mat-error *ngIf="profileForm.controls.RegistrationNumber.invalid">Registration Number is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>Registration Date</mat-label>
                      <input matInput placeholder="RegistrationDate" formControlName="RegistrationDate">
                      <mat-error *ngIf="profileForm.controls.RegistrationNumber.invalid">Registration Date is Required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Close Date</mat-label>
                    <input matInput placeholder="CloseDate" formControlName="CloseDate">
                    <mat-error *ngIf="profileForm.controls.RegistrationNumber.invalid">Close Date is Required</mat-error>
                </mat-form-field>
  
                  <mat-form-field appearance="outline">
                      <mat-label>Category</mat-label>
                      <mat-select formControlName="Category">
                          <mat-option></mat-option>
                            <mat-option *ngFor="let category of categories;" [value]="category">{{ category.name }}</mat-option>
                        </mat-select>
                      <mat-error *ngIf="profileForm.controls.Category.invalid">Category is Required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Slides Link</mat-label>
                    <input matInput placeholder="Slides Link" formControlName="Slides">
                </mat-form-field>
                </div>
                <div class="col-md-6 d-flex flex-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Pricing</mat-label>
                        <input matInput placeholder="Pricing" formControlName="Pricing">
                        <mat-error *ngIf="profileForm.controls.Pricing.invalid">Pricing is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>PublicLink</mat-label>
                        <input matInput placeholder="PublicLink" formControlName="PublicLink">
                        <mat-error *ngIf="profileForm.controls.PublicLink.invalid">PublicLink is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="Description" formControlName="Description">
                        <mat-error *ngIf="profileForm.controls.Description.invalid">Description is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label>UnitStandard</mat-label>
                      <input matInput placeholder="Partner ID" name="UnitStandard" formControlName="UnitStandard">
                      <mat-error *ngIf="profileForm.controls.UnitStandard.invalid">UnitStandard Required</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <mat-toolbar class="d-flex justify-content-end">
                  <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
                    {{ profileForm.dirty ? 'Save' : 'Next' }}
                  </button>
                </mat-toolbar>
              </form>
          </mat-card-content>
        </mat-card>
  </mat-step>
    <mat-step label="Summative Questions">
        <!-- <app-student-consent-form (emitBack)="back()" [student]="profile" (emitStudent)="saveCourseConsent($event)"></app-student-consent-form> -->
        <app-course-questions [course]="profile" [section]="'summative'"></app-course-questions>
    </mat-step>
    <mat-step label="Formative Questions">
      <!-- <app-student-consent-form (emitBack)="back()" [student]="profile" (emitStudent)="saveCourseConsent($event)"></app-student-consent-form> -->
      <app-course-questions [course]="profile" [section]="'formative'"></app-course-questions>
  </mat-step>
    <mat-step label="Learner Survey">
      <app-learner-survey></app-learner-survey>
  </mat-step>
  </mat-stepper>