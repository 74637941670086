import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faImage, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Course } from 'backend/interfaces/course.interface';
import { CourseService } from 'src/app/admin/services/course.service';
import { AssetUploadDialogComponent } from '../asset-upload-dialog/asset-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-course-questions',
  templateUrl: './course-questions.component.html',
  styleUrls: ['./course-questions.component.scss']
})
export class CourseQuestionsComponent implements OnInit {
  answerTypes = [
    {
      icon: 'drag_handle',
      text: 'Short text',
      type: 'input'
    },
    {
      icon: 'reorder',
      text: 'Long text',
      type: 'textArea'
    },
    {
      icon: 'radio',
      text: 'TrueFalse',
      type: 'TrueFalse'
    },
    {
      icon: 'select',
      text: 'Select',
      type: 'Select'
    },
    {
      icon: 'list',
      text: 'List',
      type: 'List'
    }
  ]
  @Input('course') course: Course;
  @Input('section') section: string;
  faPlus = faPlus;
  faRemove = faTimes;
  faImage = faImage;
  saving = false;
  faTrash = faTrash
  constructor(private courseService: CourseService, private fb: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog) { }
  questionsForm: FormGroup;
  questions = [];
  loading = false;

  async ngOnInit() {
    this.loading = true;
    this.questionsForm = this.fb.group({
      questions: this.fb.array([])
    });
    if (this.course.id) {
      this.courseService.getCourseQuestions(this.course.id, this.section).then(async res => {
        if (res) {
          this.questions = await res;
          this.questions.forEach((element, index) => {
            this.questionsFieldAsFormArray?.push(this.fb.group({
              answerType: element.answerType,
              body: element.body,
              duration: element.duration,
              note: element.note,
              points: element.points,
              title: element.title,
              options: this.fb.array(element.options ? element.options : []),
              listCount: element.listCount,
              listLabelType: element.listLabelType,
              imageId: element.imageId
            }));
          });
        };
      });
    }
    this.loading = false;
  }
  async addQuestion(): Promise<void> {
    this.questionsFieldAsFormArray.push(this.question());
    const qId = "q" + (this.questionsFieldAsFormArray?.length - 1);
  }

  async addOption(questionId: number): Promise<void> {
    let questions = this.questionsForm.controls['questions'];
    if(questions.get(questionId.toString()).get('options').value){
      questions.get(questionId.toString()).get('options').value.push('');
    }else{
      questions.get(questionId.toString()).get('options').setValue([''])
    }
  }

  remove(i: number): void {
    this.questionsFieldAsFormArray.removeAt(i);
    this.questions.splice(i, 1);
  }

  get questionsFieldAsFormArray(): FormArray {
    return this.questionsForm.get('questions') as FormArray;
  }

  getQuestion(questionId: number): any {
    return this.questionsForm.controls['questions'].get(questionId.toString());
  }

  getOptionsFieldAsFormArray(questionId: number): FormArray {
    return this.questionsForm.controls['questions'].get(questionId.toString())?.get('options') as FormArray;
  }

  updateQuestionOptions(e: any, questionId: number, optionId: number){
    this.questionsForm.controls['questions'].get(questionId.toString()).get('options').value[optionId] = e.target.value;
  }

  removeQuestionOptions(questionId: number, optionId: number){
    this.questionsForm.controls['questions'].get(questionId.toString()).get('options').value.splice(optionId, 1);
  }

  question(): any {
    return this.fb.group({
      title: '',
      body: '',
      note: '',
      answerType: '',
      duration: '',
      points: '',
      options: [],
      listCount: '',
      listLabelType: '',
      imageId: ''
    });
  }

  option(): any {
    return this.fb.group({
      optionBody: '',
    });
  }

  async save(): Promise<void> {
    this.saving = true;
    this.courseService.saveCourseQuestions(this.course.id, this.questionsForm.value.questions, this.section).then(async res => {
      this.openSnackBar('Questions Saved.')
      this.saving = false;
    }).catch(err => {
      this.openSnackBar(err)
    });
  }

  updateQuestion(question: any, i: number) {

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  async setQuestionType(questionId: number, questionType: MatSelectChange): Promise<void> {
    if (questionType.value === 'Select') {
      // this.questionsForm.controls['questions'].get(questionId.toString()).get('options').value.push('')
    }
  }

  openAssetUploadDialog(questionId: string): void {
    const dialogRef = this.dialog.open(AssetUploadDialogComponent, {
      data: {title: 'Upload Media'},
      width: '60%'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.questionsForm.controls['questions'].get(questionId.toString()).get('imageId').setValue(result.data.id);
        console.log(this.questionsForm.value);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  removeImage(questionId: string): void {
    this.questionsForm.controls['questions'].get(questionId.toString()).get('imageId').setValue('');
  }
}
