import { Component, OnInit } from '@angular/core';
import { AVNotification } from 'backend/interfaces/notification.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifyService } from 'src/app/services/notify.service';
import * as Parse from "parse";
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  currentUser: Parse.User;
  notifications: any;
  constructor(private authService: AuthService, private notifyService: NotifyService) { 
     this.authService.$User.subscribe(u => this.currentUser = u);
  }

  ngOnInit(): void {
    this.notifyService.notifications$.subscribe(n => {
      this.notifications = n;
    })
  }

  saveNotification(){
    const notification: AVNotification = {
      title: 'test',
      detail: 'detail of test notification',
      userId: this.currentUser.id
    }
    this.notifyService.saveNotification(notification)
  }

}
