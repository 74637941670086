import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Group } from 'backend/interfaces/group.interface';
import { Router } from '@angular/router';
import { faBinoculars, faBuilding, faInfo, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class FlipCardComponent implements OnInit {
  @Input('category') category;
  @Output() emitFollow = new EventEmitter();
  faInfo = faInfo;
  faBuilding = faBuilding;
  faBinoculors = faBinoculars;
  faUsers = faUsers;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  flip: string = 'inactive';

  toggleFlip() {
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }

  goTo(category: Group){
    this.router.navigate(['user','categories', category.id])
  }

  follow(category: Group){
    this.emitFollow.emit(category);
  }
}
