<div class="p-2 d-flex flex-column justify-content-between h-100">
    <div class="spacer"></div>
    <div class="flex-1 bg-light p-5">
        <h1 class="mat-display-1 mb-3">Reset password</h1>
        <p class="mb-5">
            We get it, stuff happens. Just enter your email address below
            and we'll send you a link to reset your password!
        </p>
    <form class="user ">
        <div class="form-group">
            <input name="email" type="email" class="form-control form-control-user" aria-describedby="emailHelp"
                placeholder="Email" [(ngModel)]="email" (focus)="errorMessage = ''">
        </div>
        <button (click)="forgotPassword()" class="btn btn-primary btn-user btn-block">
            Reset Password
        </button>
    </form>
    </div>
    <div class="spacer"></div>
    <img class="img-fluid relative link" src="assets/icon.png" (click)="goTo('home')"/>
    <hr/>
    <div>
        <h5 class="text-white">Already have an account?</h5>
    
<hr>
<div class="mb-4 text-white">
    <button type="button" mat-stroked-button routerLink="/login">Login!</button>
</div>
</div>
</div>