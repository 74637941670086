import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faTrash, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { Group } from 'backend/interfaces/group.interface';
import { MealPlan } from 'backend/interfaces/meal-plan.interface';
import { Student } from 'backend/interfaces/student.interface';
import { GroupService } from 'src/app/admin/services/group.service';
import { MealPlanService } from 'src/app/admin/services/meal-plan.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { Practitioner } from 'backend/interfaces/practitioner.interface';

export enum Allergies {
  MILK = 'Milk',
  WHEAT = 'Wheat',
  EGG = 'Egg'

}
@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  loading = false;
  faPlus = faPlus;
  faUser = faUser;
  faTrash = faTrash;
  faUpload = faUpload;
  @Input('group') group: Group = {
    id: '',
    name: '',
    description: '',
    logo: {name: '', source: ''}
  };
  logo = './../../../assets/icon.png';
  groupForm: FormGroup;
  mealPlans: MealPlan[];
  students: Student[];
  teachers: Practitioner[];
  uploadedImage: {name: string, source: string | ArrayBuffer};
  @ViewChild('fileInput') fileInput: ElementRef;

  @ViewChild('imagePreview', { read: ElementRef }) imagePreview: ElementRef;
  constructor(private practitionerService: PractitionerService, private fb: FormBuilder, private groupService: GroupService, private _snackBar: MatSnackBar, private ar: ActivatedRoute, private router: Router, private mealPlanService: MealPlanService, private dialog: MatDialog){

  }


  async ngOnInit() {
    this.loading = true;

      if(this.ar.snapshot.params['id']){
        const groupId = this.ar.snapshot.params['id'];
      }

      this.groupForm = this.fb.group({
        name: [this.group?.name ? this.group.name : '', Validators.required],
        description: [this.group?.description ? this.group.description : '', Validators.required],
        logo: ''
      })
      this.loading = false;
      if(this.group?.logo.source){
        this.logo = this.group.logo.source;
      }
  }


  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  async saveGroup(){
    this.loading = true;
    let updatedGroup;
    if(this.group.id){
      updatedGroup = {...this.groupForm.value, id: this.group.id};
    }else{
      updatedGroup = this.groupForm.value;
    }
    if(this.uploadedImage?.name){
      updatedGroup.logo = this.uploadedImage
    }
    this.fileInput.nativeElement.value = null;
    this.groupForm.controls['logo'].setValue('');

    await this.groupService.saveGroup(updatedGroup).then(res => {
      if(res){
        this.logo = res.logo.source;
      }
      this.router.navigate(['admin', 'category', res.id]);
      
      this.loading = false;
    })
    this.groupService.getGroups();
    this.groupService.getGroupCount();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

 
  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.imagePreview.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name
          }
        }
      };
    }
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {title: 'Delete', question: 'Are you sure you want to delete this item?'}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(result){
          this.deleteGroup();
        }else{
        this.dialog.closeAll();
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }

  async deleteGroup(){
    this.loading = true;
    this.groupService.deleteGroup(this.group.id).then(res => {
      this.groupService.getGroups();
      this.groupService.getGroupCount();
      this.router.navigate(['admin', 'categories']);
      this.loading = false;
    });
  }

  openStudent(id: string){
    this.router.navigate(['admin', 'student', id]);
    this.loading = false;
  }

  openTeacher(id: string){
    this.router.navigate(['admin', 'teacher', id]);
    this.loading = false;
  }
}
