import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { DataService } from 'src/app/services/data.service';
import { AlbumDialogComponent } from 'src/app/shared/album-dialog/album-dialog.component';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  courseList;
  loading = false;
  constructor(private router: Router, private dataService: DataService, public dialog: MatDialog, private locationService: LocationService) { 

  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dataService.courses$.subscribe(async res => {
      this.courseList = await res;
    })
    this.loading = false
  }

  async viewCourse(courseId): Promise<void>{
    this.router.navigate(['/course', courseId]);
  }

}
