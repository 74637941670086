import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Parent, ParentConsent } from 'backend/interfaces/parent.interface';
import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { ParentService } from 'src/app/admin/services/parent.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/auth/auth.service';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { FileViewerDialogComponent } from '../file-viewer-dialog/file-viewer-dialog.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: Parent = {
    Name: '',
    Surname: '',
    ID: '',
    Occupation: '',
    Title: '',
    Gender: '',
    Address: '',
    Mobile: '',
    Email: this.parentService.newParentEmail ? this.parentService.newParentEmail : '',
    HealthProblems: '',
    Notes: '',
    MedicalAidName: '',
    MedicalAidNumber: '',
    MedicalAidMainMember: '',
    isMainContact: false,
    PartnerID: '',
  };
  user;
  faFile = faFile;
  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  uploadedID = {name: '', type: '', source: null};
  saving = false;
  files = [];
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  constructor(private dataService: DataService, private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private parentService: ParentService, private router: Router, private _snackBar: MatSnackBar, private authService: AuthService, readonly dialog: MatDialog) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));
  }
  profileForm: UntypedFormGroup;
  // medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  async ngOnInit(): Promise<void> {
    this.authService.$User.subscribe(async (res)=>{
      if(res){
        this.user = res;
        this.dataService.getUserIdDoc(res.get('IdDocument')).then(res => {
          this.uploadedID.name = res.data.name;
          this.uploadedID.type = res.data.mimeType
        })
        this.profile = {
          Name: res.get('Name'),
          Surname: res.get('Surname'),
          ID: res.get('ID'),
          Occupation: '',
          Title: '',
          Gender: '',
          Address: '',
          Mobile: res.get('Mobile'),
          Email: res.get('email'),
          HealthProblems: '',
          Notes: '',
          MedicalAidName: '',
          MedicalAidNumber: '',
          MedicalAidMainMember: '',
          isMainContact: false,
          PartnerID: '',
        }

        // this.dataService.getUserFiles(this.user?.get('DriveId')).then(res => {
        //   this.files = res;
        // })
      }
    });
    this.profileForm = this.fb.group({
      Name: [this.profile.Name, Validators.required],
      Surname: [this.profile.Surname, Validators.required],
      ID: [this.profile.ID, Validators.required, Validators.length > 6],
      Address: [this.profile.Address, Validators.required],
      Mobile: [this.profile.Mobile, Validators.required],
      Email: [this.profile.Email, Validators.required]})
  }
  async saveProfile(){
    if(this.profileForm.dirty){
      this.saving = true;
      this.dataService.saveUser({...this.profileForm.value})
      .then((res) => {
        this.parentService.newParentEmail = undefined;
        this.openSnackBar('Parent saved');
        this.parentService.getParentCount();
        this.saving = false
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.profileForm.markAsPristine();
      this.profile.ID = this.profileForm.controls['ID'].value;
    }
  }
  
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveParentConsent(event: {student: ParentConsent, signature: string}){
    this.saving = true;
    this.parentService
      .saveParentConsent(event.student)
      .then(() => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.student);
        this.saving = false;
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  async uploadId(){
    let description = '';
    this.saving = true;
    this.dataService.uploadUserId(this.user.get('DriveId'), this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      this.saving = false;
    });
  }

  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: 'ID_'+this.user.get('ID')
              }
          };
          reader.readAsDataURL(file)
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  async getFile(fileId: string, mimeType: string, fileName: string){
    this.saving = true;
    // document.getElementById('showcaseImage').setAttribute("src", '');
    // document.getElementById('showcaseFile').setAttribute("src", '');
    return this.dataService.getUserFile(fileId, mimeType).then(res => {
      // if(mimeType.includes('image')){
      //   document.getElementById('showcaseImage').setAttribute("src", res);
      // }else{
        this.viewDriveFolder(fileName, mimeType, res, fileId)
        // document.getElementById('showcaseFile').setAttribute("src", res);
      // }
      this.selectedFile = {
        type: mimeType.includes('image') ? 'image' : 'file',
        src: res
      }
      this.saving = false;
      return res;
    }).catch(err => console.log(err))
  
  }

  async viewDriveFolder(name, mimeType, file, fileId){

    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      data: {name, mimeType, file, fileId},
      width: '90%',
      maxWidth: '100vw',
      height: '90%',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  async viewUserIdDoc(){
    this.saving = true;
    this.dataService.getUserFile(this.user.get('IdDocument'), '').then(res => {
      this.viewDriveFolder(this.uploadedID.name, this.uploadedID.type, res, res.id)
      this.selectedFile = {
        type: 'image/png',
        src: res
      }
      this.saving = false;
      return res;
    }).catch(err => console.log(err))
  
  }
}



