<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2>Confirm you want to delete?</h2>
      <p>Ref: {{ this._data.Name }}</p>
      <!-- <h4>{{ this._data.MSISDN }}</h4> -->
      <div class="row">
        <div class="col-6">
          <button (click)="cancel()" type="button" class="btn btn-default col-12">Cancel</button>
        </div>
        <div class="col-6">
          <button (click)="delete()" type="button" class="btn btn-primary col-12 text-white">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>
