import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuardService as AdminCanActivate } from './auth/admin-auth-guard.service';
import { ParentAuthGuardService as ParentCanActivate } from './auth/parent-auth-guard.service';
import { NewAppointmentComponent } from './new-appointment/new-appointment.component';
import { PdfWorkerComponent } from './pdf-worker/pdf-worker.component';
const routes: Routes = [
  // {
  //   path: "",
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: "admin",
    canActivate:[AdminCanActivate],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "user",
    canActivate:[ParentCanActivate],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  // {
  //   path: "appointment",
  //   component: NewAppointmentComponent
  // },
  // {
  //   path: "pdf-worker",
  //   component: PdfWorkerComponent
  // },
  {
    path: "",
    loadChildren: () => import('./public-site/public-site.module').then(m => m.PublicSiteModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
