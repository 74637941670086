import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { faMapMarker, faMarker, faPlus, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { SideNavService, SidenavActions } from 'src/app/admin/services/side-nav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { LocationService } from 'src/app/admin/services/location.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfilePictureDialogComponent } from '../profile-picture-dialog/profile-picture-dialog.component';
import { GroupService } from 'src/app/admin/services/group.service';
@Component({
  selector: 'app-practitioner',
  templateUrl: './practitioner.component.html',
  styleUrls: ['./practitioner.component.scss']
})
export class PractitionerComponent implements OnInit {
  saving = false;
  loading = false;
  faSearch = faSearch;
  faUser = faUser;
  faPlus = faPlus;
  faMapMarker = faMapMarker;
  sideNavActions = SidenavActions;
  addLocation = false;
  sideNavAction;
  locationsArray: Location[] = [];
  locations = [];
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild('addDrawer') addDrawer: MatSidenav;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  @Input() practitioner: Practitioner = {name: '', surname: '', locations: [], group: ''};
  @Output() emitPractitioner: EventEmitter<any> = new EventEmitter();
  @Output() closePractitioner: EventEmitter<any> = new EventEmitter();
  practitionerForm: UntypedFormGroup;
  groups = [];
  constructor(private groupService: GroupService, private fb: UntypedFormBuilder, private practitionerService: PractitionerService, private _snackBar: MatSnackBar, private locationService: LocationService, private dialog: MatDialog) { }
  async ngOnInit(): Promise<void> {
    this.groupService.groups$.subscribe(res => this.groups = res);
    this.loading = true;
    this.locationService.getLocations();
    this.locationService.locations$.subscribe(res => {
      this.locations = res;
    })
    this.sideNavAction = null;
    this.locationsArray = (this.practitioner && this.practitioner.locations) ? this.practitioner.locations : [];
    this.practitionerForm = this.fb.group({
      name: [this.practitioner ? this.practitioner.name : '', Validators.required],
      surname: [this.practitioner ? this.practitioner.surname : '', Validators.required],
      idNumber: [this.practitioner.idNumber, Validators.required, Validators.length > 6],
      occupation: [this.practitioner.occupation, Validators.required],
      title: [this.practitioner.title, Validators.required],
      gender: [this.practitioner.gender, Validators.required],
      address: [this.practitioner.address, Validators.required],
      mobile: [this.practitioner.mobile, Validators.required],
      email: [this.practitioner.email, Validators.required],
      group: [this.practitioner.group ? this.groups.find(g => g.id === this.practitioner.group) : '', Validators.required]
    });
    this.loading = false;
  }
  async savePractitioner(){
      this.saving = true;
      const saveObject = {
        locations: this.locationsArray,
        name: this.practitionerForm.controls['name'].value,
        surname: this.practitionerForm.controls['surname'].value,
        idNumber: this.practitionerForm.controls['idNumber'].value,
        occupation: this.practitionerForm.controls['occupation'].value,
        title: this.practitionerForm.controls['title'].value,
        gender: this.practitionerForm.controls['gender'].value,
        address: this.practitionerForm.controls['address'].value,
        mobile: this.practitionerForm.controls['mobile'].value,
        email: this.practitionerForm.controls['email'].value,
        group: this.practitionerForm.controls['group'].value.id,
      }
      this.practitionerService
      .savePractitioner(saveObject)
      .then((res) => {
        this.saving = false;
        this.openSnackBar('Practitioner saved');
        this.practitionerService.getPractitioners(10, 0);
        this.practitionerService.getPractitionerCount();
        this.closePractitioner.emit();
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  openDrawer(action: SidenavActions): void {
    this.sideNavAction = action;
    this.drawer.open()
  }

  addLocationToPractitioner(location: Location, index: number){
    this.locationsArray.push(location);
    this.locations.splice(index, 1);
  }

  openDialog(config?: MatDialogConfig) {
    return this.dialog.open(this.dialogTemplate, config);
  }

  openProfilePictureDialog(src?: any): void {
    const dialogRef = this.dialog.open(ProfilePictureDialogComponent, {
      data: {title: 'Upload Picture', practitionerId: this.practitioner.idNumber, currentPicture: src ? src : ''},
      width: '60%'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        // console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  }

}
