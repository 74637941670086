import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Course } from 'backend/interfaces/course.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CourseService } from 'src/app/admin/services/course.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatDialog } from '@angular/material/dialog';
import { SubmisionCompleteDialogComponent } from '../submision-complete-dialog/submision-complete-dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-course-answers',
  templateUrl: './course-answers.component.html',
  styleUrls: ['./course-answers.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false, showError: true},
    },
  ],
})
export class CourseAnswersComponent implements OnInit {
  answerTypes = [
    {
      icon: 'drag_handle',
      text: 'Short text',
      type: 'input'
    },
    {
      icon: 'reorder',
      text: 'Long text',
      type: 'textArea'
    },
    {
      icon: 'radio',
      text: 'TrueFalse',
      type: 'TrueFalse'
    },
    {
      icon: 'select',
      text: 'Select',
      type: 'Select'
    }
  ]
  @Input('course') course: Course | any;
  @Input('section') section: string;
  faPlus = faPlus;
  faRemove = faTimes;
  saving = false;
  loading = false;
  @ViewChild('stepper') private stepper: MatStepper;
  profileForm: UntypedFormGroup;
  stepperOrientation: Observable<StepperOrientation>;
  constructor(private authService: AuthService, private dataService: DataService, private fb: FormBuilder, private _snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver, readonly dialog: MatDialog, private router: Router) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'))}
  answersForm: FormGroup;
  answers = [];
  userIdNumber;
  expired;

  async ngOnInit(): Promise<void>{
    this.loading = true;
    this.authService.$User.subscribe((res) => {
      this.userIdNumber = res?.get('ID');
    })
    if(this.course.Order){
      this.expired = new Date(this.course.Order.get('ExpiryDate'))
    }
    if(this.course.Status === 'enrolled'){
      if(this.section === 'formative'){
        this.answers = await this.course.Course.get('FormativeQuestions');
      }else{
        this.answers = await this.course.Course.get('SummativeQuestions');
      }
      if(this.answers){
      this.answers = await this.answers.map(element => {
          if(element.answerType === 'List'){
            let list = [];
            for(let i = 0; i < element.listCount; i++) {
              list.push('')
            }
            return {
              answerType: element.answerType,
              body: element.body,
              duration: element.duration,
              note: element.note,
              points: element.points,
              title: element.title,
              answer: element.answer,
              listCount: element.listCount,
              listLabelType: element.listLabelType,
              imageId: element.imageId,
              listItems: this.fb.array(element.listItems ? element.listItems : list),
            }
          }else{
            return {
              answerType: element.answerType,
              body: element.body,
              duration: element.duration,
              note: element.note,
              points: element.points,
              title: element.title,
              answer: element.answer,
              imageId: element.imageId,
              options: this.fb.array(element.options ? element.options : [''])
            }
          }
      })}
    }else{
      if(this.section === 'formative'){
        this.answers = await this.course.FormativeAnswers;
      }else{
        this.answers = await this.course.SummativeAnswers;
      }
      if(this.answers){
      this.answers = this.answers.map(element => {
        if(element.answerType === 'List'){
          let list = [];
          for(let i = 0; i < element.listCount; i++) {
            list.push('')
          }
          return {
            answerType: element.answerType,
            body: element.body,
            duration: element.duration,
            note: element.note,
            points: element.points,
            title: element.title,
            answer: element.answer,
            listCount: element.listCount,
            listLabelType: element.listLabelType,
            imageId: element.imageId,
            listItems: this.fb.array(element.listItems ? element.listItems : list),
          }
        }else{
          return {
            answerType: element.answerType,
            body: element.body,
            duration: element.duration,
            note: element.note,
            points: element.points,
            title: element.title,
            answer: element.answer,
            imageId: element.imageId,
            options: this.fb.array(element.options ? element.options : [''])
        }
        }
        
    })}
    }

      this.answersForm = this.fb.group({
        answers: this.fb.array([])
      });
      if(this.answers){
      await this.answers.forEach(element => {
        this.answersFieldAsFormArray.push(this.fb.group(element));
      });
    }
        this.loading = false;
  }

  get answersFieldAsFormArray(): FormArray {
    return this.answersForm.get('answers') as FormArray;
  }

  answer(): any {
    return this.fb.group({
      title: '',
      body: '',
      note: '',
      answerType: '',
      duration: '',
      points: '',
      answer: ['', Validators.required]
    });
  }

  async save(): Promise<void>{
    this.saving = true;
    this.loading = true;
    this.dataService.saveAssessment(this.userIdNumber, this.course.id, this.answersForm.value.answers, 'saved', this.section).then(async res => {
      this.openSnackBar('Answers Saved.');
      this.dataService.getEnrollmentsByUserId(this.userIdNumber);
      this.saving = false;
      this.loading = false;
    }).catch(err => {
      this.openSnackBar(err)
      this.loading = false;
    });
  }


  async submit(): Promise<void>{
    this.saving = true;
    this.loading = true;
    this.dataService.submitAssessment(this.userIdNumber, this.course.id, this.answersForm.value.answers, 'submitted', this.section).then(async res => {
      await this.dataService.getEnrollmentsByUserId(this.userIdNumber);
      this.saving = false;
      this.loading = false;
      this.openSubmissionCompleteDialog();
    }).catch(err => {
      this.openSnackBar(err)
      this.loading = false;
    }); 
  }

  updateQuestion(question: any, i: number){
    // let formArr: FormArray = this.questionsForm.controls['questions'];
    // console.log(formArr.controls[i]);
    console.log(question);
  }

  updateAnswerListItem(e: any, answerId: number, itemId: number){
    // console.log(e.target.value)
    // this.answersForm.controls['answers'].get(answerId.toString()).get('listItems').value[itemId] = e.target.value;
    // console.log(this.answersForm.controls['answers'])
    const answrs = this.answersForm.get('answers') as FormArray;
    const answr = answrs.at(answerId) as FormGroup;
    const list = answr.controls['listItems'] as FormArray;
    list.at(itemId).setValue(e.target.value);
    // list.at(itemId).patchValue(e.target.value);
// console.log(this.answersForm.controls['answers'].get(answerId.toString()).get('listItems').value)
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  answerListArray(answerId: number): FormArray{
    const answrs = this.answersForm.get('answers') as FormArray;
    return answrs.at(answerId).get('itemList') as FormArray
  }

  getAphabetLetter(index: number){
    const alpha = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
      'M', 'N', 'O', 'P', 'Q', 'R',  'S', 'T', 'U', 'V', 'W', 'X',
      'Y', 'Z' ]
      return alpha[index]
  }

  async openSubmissionCompleteDialog(){
    console.log(this.course)
    const dialogRef = this.dialog.open(SubmisionCompleteDialogComponent, {
      data: {title: this.course.Course.get('Name'), test: ''}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.router.navigate(['user', 'dashboard']);
      } else {
        this.dialog.closeAll();
      }
    });
  }

}
