import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faMicrophone, faMicrophoneAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CalendarEvent } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocationService } from 'src/app/admin/services/location.service';
import { SidenavActions, SideNavService } from 'src/app/admin/services/side-nav.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';
import { Location } from '../../../../backend/interfaces/location.interface';
import { DataService } from 'src/app/services/data.service';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Service } from 'backend/interfaces/service.interface';
import { MatSelectChange } from '@angular/material/select';
import { TimeSlot } from 'src/app/new-appointment/new-appointment.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss']
})
export class AppointmentFormComponent implements OnInit {
  appointmentForm: UntypedFormGroup;
  locations: Location[] = [];
  practitioners: Practitioner[] = [];
  services: Service[];
  timeSlots: TimeSlot[] = [];
  loadingPractitioners = false;
  selectedDate: Date;
  startAt = new Date('2023/09/11');
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  year: any;
  DayAndDate: string;
  selectedLocation: string;
  practitioners$: BehaviorSubject<Practitioner[]> = new BehaviorSubject([]);
  selectedPractitioner;
  newAppointmentForm = this.fb.group({
    appointmentType: ['', Validators.required],
    appointmentLocation: ['', Validators.required],
    appointmentDate: ['', Validators.required],
    appointmentStart: ['', Validators.required],
    appointmentDuration: ['60 min'],
    studentName: ['', Validators.required],
    studentSurname: ['', Validators.required],
    studentMobile: ['', Validators.required],
    studentEmail: ['', Validators.required],
    additionalNotes: [''],
    preferredPractitioner: ['Any Available'],
  });
  loading = false;
  sidenavActions = SidenavActions;
  @Input() appointment: CalendarEvent;
  @Input() appointmentAction?: SidenavActions;
  faTrash = faTrash;
  @Output('openAppointment') openAppointment: EventEmitter<any> = new EventEmitter(null);
  constructor(public dialog: MatDialog, private fb: UntypedFormBuilder, public sideNavService: SideNavService, private appointmentService: AppointmentService, private router: Router, private _snackBar: MatSnackBar, private locationService: LocationService, private dataService: DataService) { 

  }
  async ngOnInit(): Promise<void> {

    this.appointment = null;
    this.dataService.services$.subscribe(res => {
      this.services = res;
    });

    this.dataService.locations$.subscribe(res => {
      this.locations = res;
    });
    this.dataService.practitioners$.subscribe(res => {
      this.practitioners = res;
    });

    this.appointmentService.appointment$.subscribe(appointment => {
      let duration = 60;
      this.selectedPractitioner = 'Any Available';
      if(appointment)
      {
        console.log(appointment);
        this.appointment = appointment;
        if(appointment.meta?.duration){
          duration = appointment.meta.duration;
          appointment.end = moment(new Date(appointment.start)).add(duration, 'minutes').toDate();
        }else{
          duration = moment(appointment.end).diff(appointment.start, 'minutes');
        }
        if(appointment.meta?.preferredPractitioner){
          this.selectedPractitioner = appointment.meta.preferredPractitioner.name + ' ' + appointment.meta.preferredPractitioner.surname;
        }
        this.selectedLocation = appointment.meta?.appointmentLocation;
      } else {
        this.selectedLocation = this.locations[0].name;
      }
      // console.log(appointment)
      this.appointmentForm = this.fb.group(
        {
          summary: [appointment ? appointment.meta?.appointmentType?.summary : '', Validators.required],
          location: [appointment ? appointment.meta?.appointmentLocation : ''],
          date: [appointment ? new Date(appointment.start) : '', Validators.required],
          start: [{value: appointment ? new Date(appointment.start).toLocaleTimeString() : '', disabled: true}, Validators.required],
          studentName: [{value: appointment ? appointment.meta?.studentName : '', disabled: true}],
          studentSurname: [{value: appointment ? appointment.meta?.studentSurname : '', disabled: true}],
          attendeeEmail: [appointment ? appointment.meta?.studentEmail : '', Validators.required],
          notes: [appointment ? appointment.meta?.additionalNotes : '']
        });
      this.selectedDate = appointment.start;
      this.newAppointmentForm.controls['appointmentLocation'].setValue(this.selectedLocation);
      this.newAppointmentForm.controls['appointmentDate'].setValue(appointment ? new Date(appointment.start) : new Date());
      this.newAppointmentForm.controls['appointmentType'].setValue(this.services.find(s => s.title === appointment.meta?.appointmentType?.summary));
      this.newAppointmentForm.controls['appointmentDuration'].setValue(this.services[0].duration);
      if(this.selectedLocation && this.selectedDate){
      this.getAvailableTimes(this.selectedDate);
      }
    }, error => {
      console.log(error);
    })
  }

  saveBooking(): void {
    if(this.appointment.meta.id)
    {
      this.updateAppointment();
      return;
    }
    this.loading = true;
    this.appointment.start = this.mapDateTime(this.appointmentForm.controls['date'].value, this.appointmentForm.controls['start'].value);
    this.appointment.end = this.mapDateTime(this.appointmentForm.controls['date'].value, this.appointmentForm.controls['end'].value);
    
    const appointment: GoogleCalendarEvent = {
      summary: this.appointmentForm.controls['summary'].value,
      location: this.appointmentForm.controls['location'].value,
      description: this.appointmentForm.controls['description'].value,
      start: {
        dateTime: moment(this.appointment.start).toISOString(),
        timeZone: 'Africa/Johannesburg',
      },
      end: {
        dateTime: moment(this.appointment.end).toISOString(),
        timeZone: 'Africa/Johannesburg',
      },
      attendees: [
        {email: this.appointmentForm.controls['attendeeEmail'].value}
      ],
      reminders: {
        useDefault: false,
        overrides: [
          {method: 'email', 'minutes': 24 * 60},
          {method: 'popup', 'minutes': 3 * 60},
        ],
      }
    };
    this.appointmentService.createAppointment(appointment).then(()=> {
      this.openSnackBar('Appointment successfully created');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.appointment = null;
      this.selectedPractitioner = null;
      this.selectedLocation = null;
      this.selectedDate = null;

      this.loading = false;
    }).catch((err)=> {
      this.openSnackBar(err);
      this.loading = false;
    });
  }

  async rejectAppointmentRequest(): Promise<void>{
    this.loading = true;
    const appointment = {
      appointmentType: this.appointmentForm.controls['summary'].value,
      appointmentLocation: this.appointmentForm.controls['location'].value,
      appointmentDate: this.appointmentForm.controls['date'].value.toString(),
      studentEmail: this.appointmentForm.controls['attendeeEmail'].value,
      additionalNotes: this.appointmentForm.controls['notes'].value,
      colorId: this.locations.find(l => l.name === this.selectedLocation).colorId,
      id: this.appointment.id,
      appointmentDuration: this.services.find(s => s.title === this.appointmentForm.controls['summary'].value).duration,
      accepted: false
    }
    this.appointmentService.rejectAppointment(appointment.id).then(()=> {
      this.openSnackBar('Appointment successfully updated');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.appointment = null;
      this.loading = false;
    }).catch((err)=> {
      this.openSnackBar(err);
      this.loading = false;
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  async updateAppointment(): Promise<void>{
    this.loading = true;
    const appointment = {
      appointmentType: this.appointmentForm.controls['summary'].value,
      appointmentLocation: this.appointmentForm.controls['location'].value,
      appointmentDate: this.appointmentForm.controls['date'].value.toString(),
      studentEmail: this.appointmentForm.controls['attendeeEmail'].value,
      studentName: this.appointmentForm.controls['studentName'].value,
      studentSurname: this.appointmentForm.controls['studentSurname'].value,
      additionalNotes: this.appointmentForm.controls['notes'].value,
      colorId: this.locations.find(l => l.name === this.selectedLocation).colorId,
      id: this.appointment.id,
      appointmentDuration: this.services.find(s => s.title === this.appointmentForm.controls['summary'].value).duration,
    }
    this.appointmentService.updateAppointment(appointment).then(()=> {
      this.openSnackBar('Appointment successfully updated');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.appointment = null;
      this.loading = false;
    }).catch((err)=> {
      this.openSnackBar(err);
      this.loading = false;
    });
  }

  async acceptAppointmentRequest(): Promise<void>{
    this.loading = true;
    const appointment = {
      appointmentType: this.appointmentForm.controls['summary'].value,
      appointmentLocation: this.appointmentForm.controls['location'].value,
      appointmentDate: this.appointmentForm.controls['date'].value.toString(),
      studentEmail: this.appointmentForm.controls['attendeeEmail'].value,
      additionalNotes: this.appointmentForm.controls['notes'].value,
      colorId: this.locations.find(l => l.name === this.selectedLocation).colorId,
      id: this.appointment.id,
      appointmentDuration: this.services.find(s => s.title === this.appointmentForm.controls['summary'].value).duration,
      accepted: true
    }
    this.appointmentService.updateAppointment(appointment).then(()=> {
      this.openSnackBar('Appointment successfully updated');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.appointment = null;
      this.loading = false;
    }).catch((err)=> {
      this.openSnackBar(err);
      this.loading = false;
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {title: 'Delete', question: 'Are you sure you want to delete this item?'}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(result){
          this.deleteBooking();
        }else{
        this.cancel();
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }

  deleteBooking(): void{
    this.loading = true;
    this.appointmentService.deleteAppointment(this.appointment.meta.id).then(()=> {
      this.openSnackBar('Appointment successfully deleted');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.loading = false;
    }).catch((err)=> {
      this.openSnackBar(err);
      this.loading = false;
    });
  }


  cancel(){
    this.appointment = null;
    this.router.navigate(['admin', 'appointments']);
  }

  openAppointmentDetail(): void {
    this.openAppointment.emit(this.appointment.id);
  }


  labelFn(value: number): string {
    return `${value} minutes`;
  }

  mapDateTime(dateString: string, timeString: string): any{
    const newDate = moment(dateString);
    let newDateTime = moment(timeString, 'HH:mm:ss');
    newDateTime.set('date', newDate.get('date'));
    newDateTime.set('month', newDate.get('month'));
    newDateTime.set('year', newDate.get('year'));
    return newDateTime;
  }

  async setLocation(event: MatSelectChange): Promise<void> {
    this.loadingPractitioners = true;
    this.selectedLocation = event.value;
    this.newAppointmentForm.controls['appointmentLocation'].setValue(event.value);
    this.selectedPractitioner = 'Any Available';
    const practitionersAtLocation = this.practitioners.filter((obj) => {
      if(obj.locations){
        if(obj.locations.some(location => location.name === this.selectedLocation)){
          return obj;
        }
      }
    });
    this.practitioners$.next(practitionersAtLocation);
    if(this.selectedDate){
      this.getAvailableTimes(this.selectedDate);
    }
    this.loadingPractitioners = false;
  }


  setPractitioner(event: MatSelectChange): void {
    this.selectedPractitioner = event.value;
    // this.stepper.next();
  }

  myDateFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const now = moment().subtract(1, 'days').toDate();
    now.setHours(0,0,0,0);
    // Prevent Saturday and Sunday from being selected.
    // return day !== 0 && day !== 7 && d > now;
    return d > now;
  }
  setDate(date: string) {
    this.newAppointmentForm.controls['appointmentDate'].setValue(date);
  }

  selectDate(event: any) {
    if(this.selectedLocation){
      this.timeSlots = [];
      const hourFromPreviousSelectedDate = this.selectedDate.getHours();
      const minutesFromPreviousSelectedDate = this.selectedDate.getMinutes();
      this.selectedDate = event.value;
      let newDateSelectedWithTimeFromPreviouslySelected = new Date(this.selectedDate.setHours(hourFromPreviousSelectedDate));
      newDateSelectedWithTimeFromPreviouslySelected = new Date(newDateSelectedWithTimeFromPreviouslySelected.setMinutes(minutesFromPreviousSelectedDate));
      if(this.selectedLocation){
        this.getAvailableTimes(this.selectedDate);
      }
    }
  }

  async getAvailableTimes(_event) {
    this.timeSlots = [];
    const date = new Date(_event);
    const duration = parseInt(this.newAppointmentForm.controls['appointmentDuration'].value.split(' ')[0]);
    await this.dataService.getAvailableTimes(duration, date.getDate(), date.getMonth(), date.getFullYear(), this.selectedLocation, this.selectedPractitioner).then(async res => {
      await res.forEach(slot => {
        this.timeSlots.push({time: slot, selected: false})
      });
    })
  }

  setAppointmentType(_event): void {
    console.log(_event)
    const appointmentType = this.services.find(s => s.title === _event.value);
    this.newAppointmentForm.controls['appointmentDuration'].setValue(appointmentType ? appointmentType.duration : '60 min');
    if(this.selectedLocation && this.selectedDate){
    this.getAvailableTimes(this.selectedDate);
    }
  }

  makeAppointment(): void {
    this.loading = true;
    const appointment = {
      appointmentType: this.newAppointmentForm.controls['appointmentType'].value,
      appointmentLocation: this.newAppointmentForm.controls['appointmentLocation'].value,
      appointmentDate: this.newAppointmentForm.controls['appointmentDate'].value.toString(),
      appointmentDuration: this.newAppointmentForm.controls['appointmentDuration'].value,
      studentName: this.newAppointmentForm.controls['studentName'].value,
      studentSurname: this.newAppointmentForm.controls['studentSurname'].value,
      studentMobile: this.newAppointmentForm.controls['studentMobile'].value,
      studentEmail: this.newAppointmentForm.controls['studentEmail'].value,
      additionalNotes: this.newAppointmentForm.controls['additionalNotes'].value.toString(),
      preferredPractitioner: this.selectedPractitioner,
      colorId: this.locations.find(l => l.name === this.selectedLocation).colorId
    }
    this.appointmentService.createAppointmentRequest(appointment).then(res => {
      this.openSnackBar('Appointment successfully created');
      this.sideNavService.close();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'appointments']));
      this.appointment = null;
      this.loading = false;
    }).catch(err => console.log(err));
  }

  selectTimeSlot(timeSlot: string): void {
    const time = moment(timeSlot);
    this.selectedDate = time.toDate();
    this.newAppointmentForm.controls['appointmentDate'].setValue(this.selectedDate);
    this.newAppointmentForm.controls['appointmentStart'].setValue(timeSlot);
    this.appointmentForm.controls['date'].setValue(this.selectedDate);
    this.appointmentForm.controls['start'].setValue(this.selectedDate);
  }

  setTimeFromDropdown(event: MatSelectChange){
  if(event.value){
    this.selectTimeSlot(event.value);
  }
  }

}
