
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading || !courseList">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>


    <div class="d-flex justify-content-between m-3">
        <h1 class="mat-display-1">Courses</h1>
    </div>

            <div *ngIf="courseList?.length > 0 && !loading" class="row m-0 p-2 mr-2 flex-1">
                <div *ngFor="let a of courseList" class="col-md-12 border-primary mb-3 p-3">
                    <h1>
                        {{a.Name}}
                    </h1>
                    <div class="row">
                        <div class="col-md-6">
                            <p>
                                {{a.Category.Name}}
                            </p>
                            <p>UnitStandard: {{a.UnitStandard}}</p>
                            <p>{{a.Description}}</p>

                            <!-- <p>Pricing: {{a.Pricing}}</p>
                            <p>RegistrationDate: {{a.RegistrationDate}}</p>
                            <p>CloseDate: {{a.CloseDate}}</p>
                            <p>Pricing: {{a.Pricing}}</p> -->
                        </div>
                    </div>
                    <button mat-raised-button color="primary" (click)="viewCourse(a.id)">View Course</button>
                </div>
            </div>



