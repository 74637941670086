<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div *ngIf="course && context === 'course' || !context">
<div *ngIf="course.DriveId">
        <h3 *ngIf="files?.length < 1" class="m-3 text-muted">No files found</h3>
        <div class="d-flex justify-content-center" *ngIf="files?.length > 0">
            <!-- <p>{{parentId}}</p> -->
            <div *ngIf="files && files.length > 0" class="flex-1 align-items-center scrollable mr-2">
            <div *ngFor="let file of files" class="mb-3 d-flex">
                        <div class="flex-1 link bg-primary p-2 fileList border-radius-5 text-white text-center" (click)="getFile(file.id, file.mimeType, file.name)">
                          <fa-icon class="text-white mr-3" [icon]="faFile"></fa-icon> {{file.name}}
                        </div>
                        <button *ngIf="isAdmin" mat-raised-button color="warn" (click)="removeFile(file.id, file.name)">
                          <fa-icon [icon]="faTrash" class="text-white"></fa-icon>
                        </button>
              </div>
            </div>
            
<!-- 
              <div *ngIf="files?.length > 0" class="relative flex-1" >
    
                <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
                    <div>
                        <mat-spinner color="accent"></mat-spinner>
                    </div>
                </div>
                <div class="showcase scrollable">
                <iframe id="showcaseFile" width="100%" height="100%"></iframe>
            </div> -->
    
        </div>

  </div>
  <ng-container *ngIf="isAdmin">
<button mat-stroked-button color="accent" (click)="createDriveFolder()" *ngIf="!course.DriveId">Create Drive</button>
    <div class="m-3" *ngIf="course?.DriveId" mat-dialog-actions>
        <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileInput

      /><button [disabled]="!uploadedFile?.source" mat-stroked-button color="primary" (click)="uploadFile()">upload</button>
    </div>
  </ng-container>
  <h3 *ngIf="!course" class="m-3 text-muted">No files found</h3>
</div>
<div class="p-3" *ngIf="user && context === 'user'">
  <h1>Your Files</h1>
  <ng-container>
    <button mat-stroked-button color="accent" (click)="createUserDriveFolder()" *ngIf="!user.DriveId">Create Drive</button>
        <div class="m-3" *ngIf="user?.DriveId" mat-dialog-actions>
            <input
            type="file"
            class="file-input"
            (change)="onFileSelected($event)"
            #fileInput
    
          /><button [disabled]="!uploadedFile?.source" mat-stroked-button color="primary" (click)="uploadUserFile()">upload</button>
        </div>
      </ng-container>
  <h3 *ngIf="!user" class="m-3 text-muted">No files found</h3>
  <div class="d-flex justify-content-center" *ngIf="files?.length > 0">
    <!-- <p>{{parentId}}</p> -->
    <div *ngIf="files && files.length > 0" class="flex-1 align-items-center scrollable">
    <div *ngFor="let file of files" class="mb-3 d-flex flex-1">
      <ng-container *ngIf="!file.name.includes('ID_')">
                <div class="flex-1 link bg-primary p-2 fileList border-radius-5 text-white text-center" (click)="getFile(file.id, file.mimeType, file.name)">
                  <fa-icon class="text-white mr-3" [icon]="faFile"></fa-icon> {{file.name}}
                </div>
                <button mat-raised-button color="warn" (click)="removeFile(file.id, file.name)">
                  <fa-icon [icon]="faTrash" class="text-white"></fa-icon>
                </button>
              </ng-container>
      </div>
    </div>

</div>
</div>

