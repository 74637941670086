import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    email: string;
    password: string;
    errorMessage: string;
    loading: boolean;
    passwordVisible: boolean = false;
    faPhoneVolume = faPhoneVolume;
    faEye = faEye;
    faEyeSlash = faEyeSlash;
    loginForm: UntypedFormGroup;
    constructor(
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.loading = false;
        this.loginForm = this.fb.group({
            email: [this.activatedRoute.snapshot.queryParams.email
                ? this.activatedRoute.snapshot.queryParams.email
                : '', [Validators.email, Validators.required]],
            password: ['', Validators.required]
        })
        // this.email = this.activatedRoute.snapshot.queryParams.email
        //     ? this.activatedRoute.snapshot.queryParams.email
        //     : '';
    }

    async login() {
        this.loading = true;
        const redirect = await this.activatedRoute.snapshot.queryParams.returnUrl;
        this.authService
            .login(this.loginForm.controls['email'].value.toLowerCase(), this.loginForm.controls['password'].value)
            .then((user) => {
                console.log(user);
                if (redirect) {
                    this.router.navigateByUrl(redirect);
                } else {
                    console.log(user.get('role'))
                    if (user.get('role') && user.get('role') === 'admin') {
                      this.router.navigate(['admin', 'dashboard']);
                    } else {
                        this.router.navigate(['user']);
                    }
                }
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                this.errorMessage = err;
            });
    }

    togglePassword(show) {
        this.passwordVisible = show;
    }

    goTo(route: string){
        this.router.navigate([route]);
    }
}
