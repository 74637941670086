
   <div class="scrollable h-100">
   <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
        <mat-card class="m-3">
            <mat-card-content>
              <h1 class="mb-3">Your Details</h1>
                <form *ngIf="profileForm" class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <div class="d-flex row p-3">
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="Name" name="name" formControlName="Name">
                          <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Surname</mat-label>
                          <input matInput placeholder="Surname" formControlName="Surname">
                          <mat-error *ngIf="profileForm.controls.Surname.invalid">Surname is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field *ngIf="!profile.Id" appearance="outline">
                          <mat-label>ID</mat-label>
                          <input matInput placeholder="Id" formControlName="ID">
                          <mat-error *ngIf="profileForm.controls.ID.invalid">ID is Required</mat-error>
                      </mat-form-field>
                      
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Address</mat-label>
                          <input matInput placeholder="Address" formControlName="Address">
                          <mat-error *ngIf="profileForm.controls.Address.invalid">Address is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Mobile</mat-label>
                          <input matInput placeholder="Mobile" formControlName="Mobile">
                          <mat-error *ngIf="profileForm.controls.Mobile.invalid">Mobile is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Email" formControlName="Email">
                          <mat-error *ngIf="profileForm.controls.Email.invalid">Email is Required</mat-error>
                      </mat-form-field>
                  </div>
                </div>
                <mat-card-footer class="d-flex p-3">
                    <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
                      Save
                    </button>
                  </mat-card-footer>
                </form>
            </mat-card-content>
          </mat-card>

          <div class="border-primary m-3">
            <app-gdrive [context]="'user'"></app-gdrive>
          </div>
        
          <div class="m-3 p-4 border-primary">
            <div class="d-flex flex-column justify-content-center">
              <h1 class="mb-3">ID Document</h1>
              <p *ngIf="!user?.get('IdDocument')">Please complete your profile by uploading a copy of your ID.</p>
              <ng-container *ngIf="user?.get('IdDocument')">
                <div class="flex-1 p-2 text-success" (click)="viewUserIdDoc()">
                  <fa-icon class="mr-3" [icon]="faFile"></fa-icon> {{uploadedID?.name}}
                </div>
              </ng-container>
              <form>
                <div class="m-3" *ngIf="user?.get('DriveId')">
                    <input
                    type="file"
                    class="file-input"
                    (change)="onFileSelected($event)"
                    #fileInput
            
                  /><button [disabled]="!uploadedFile?.source" mat-stroked-button color="primary" (click)="uploadId()">upload</button>
                </div>
                <h3 *ngIf="!user" class="m-3 text-muted">No files found</h3>
              </form>
            </div>
            <!-- <div *ngIf="files && files.length > 0" class="flex-1 align-items-center scrollable border-primary p-2 mr-2">
              <div *ngFor="let file of files" class="mb-3 link bg-primary p-2 fileList border-radius-5 text-white text-center" (click)="getFile(file.id, file.mimeType, file.name)">
                          <fa-icon class="text-white mr-3" [icon]="faFile"></fa-icon> {{file.name}}
                </div>
              </div> -->
          </div>
    
        </div>