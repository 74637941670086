import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faCaretLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CancelDialogComponent } from 'src/app/shared/cancel-dialog/cancel-dialog.component';
import { Location } from '../../../../backend/interfaces/location.interface';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { DataService } from 'src/app/services/data.service';
import { Course } from 'backend/interfaces/course.interface';
@Component({
  selector: 'app-view-course',
  templateUrl: './view-course.component.html',
  styleUrls: ['./view-course.component.scss']
})
export class ViewCourseComponent implements OnInit {
  loading = false;
  courseId;
  faTimes  = faTimes;
  faBack = faCaretLeft;
  courseName: string;
  course: Course;
  constructor(private dataService: DataService, private studentService: StudentService, private locationService: LocationService, private router: Router, private ar: ActivatedRoute, public dialog: MatDialog) { 
    if(this.ar.snapshot.params['id']){
      this.courseId = this.ar.snapshot.params['id'];
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
      await this.dataService.getCourseById(this.courseId).then(res => {
        this.courseName = res.Name;
        this.course = res;
      });
      this.
      loading = false;
  }

  enroll(){
    this.router.navigate(['auth','register']);
  }
  
}
