<div class="p-2 d-flex flex-column justify-content-between h-100">
  <div class="spacer"></div>
  <div class="flex-1 bg-light p-5">
    <h1 class="mat-display-1 mb-3">Create an Account</h1>

    <p class="mat-subheading-2 mb-2">
      Please complete the form below to get started on your marketing journey.
    </p>
    <h5>Already have an account? <button type="button" mat-stroked-button routerLink="/login">
      Sign in
    </button>
  </h5>
    <div>
    </div>
    <form class="user" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input
          type="text"
          formControlName="id"
          class="form-control form-control-user"
          placeholder="ID Number"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          formControlName="name"
          class="form-control form-control-user"
          placeholder="Name"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          formControlName="surname"
          class="form-control form-control-user"
          placeholder="Surname"
        />
      </div>
      <div class="form-group">
        <input
          type="text"
          formControlName="mobile"
          class="form-control form-control-user"
          placeholder="Mobile Number"
        />
      </div>
      <div class="form-group">
        <input
          type="email"
          formControlName="email"
          class="form-control form-control-user"
          placeholder="Email Address"
        />
      </div>
      <div class="form-group input-group">
        <input
          name="password"
          type="password"
          placeholder="Password"
          id="password"
          name="password"
          [type]="passwordVisible ? 'text' : 'password'"
          class="form-control form-control-user"
          formControlName="password"
        />
        <div class="input-group-append">
          <span
            class="input-group-text password-visibility"
            id="basic-addon2"
            (mousedown)="togglePassword(true)"
            (mouseup)="togglePassword(false)"
          >
            <fa-icon
              class="eye-icon"
              *ngIf="!passwordVisible"
              [icon]="faEye"
            ></fa-icon>
            <fa-icon
              class="eye-icon"
              *ngIf="passwordVisible"
              [icon]="faEyeSlash"
            ></fa-icon>
          </span>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-user btn-block">
        Register Account
      </button>
    </form>
  </div>
  <div class="spacer"></div>
  <img class="img-fluid relative link" src="assets/icon.png" (click)="goTo('home')"/>
  <hr/>
  <div class="text-white">
    <h5>Already have an account?</h5>
    <div class="mb-4 text-white">
      <button type="button" mat-stroked-button routerLink="/login">
        Sign in
      </button>
    </div>
  </div>
</div>
