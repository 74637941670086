<!-- 


<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

<div class="p-5 m-3 cover-photo relative">
    <h1 class="bg-50perc-white p-3">{{courseName}}</h1>
</div> -->



<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
  <div class="scrollable p-2 h-100 w-100 relative">
  <div *ngIf="!loading && course" class="m-0 row h-100 justify-content-center">
    <div class="col-md-8 m-0 border-radius-5 justify-content-center p-3 border-primary relative bg-50perc-white">
      <button class="fab-top-left" type="button" mat-mini-fab color="accent" routerLink="../../courses">
        <fa-icon [icon]="faBack"></fa-icon>
      </button>
        <div class="d-flex flex-wrap justify-content-center flex-column align-items-center">
  
            <div class="m-3 d-flex justify-content-center flex-column justify-content-center text-center">
              <h2 class="m-0">{{course.Name}}</h2>
              <p class="m-0">{{course.Description ? course.Description : ''}}</p>
              <p class="m-0">{{course.UnitStandard ? 'Unit Standard: ' + course.UnitStandard : ''}}</p>
            </div>
            <button mat-raised-button (click)="enroll()">Enroll</button>
        </div>
            <!-- <button mat-stroked-button color="accent" class="text-left btn-block" (click)="createAccount()" *ngIf="!parent.AccountID">Create Account</button> -->
            <!-- <button mat-raised-button class="text-left btn-block" routerLink="account" routerLinkActive="active">Account</button> -->
            
            <!-- <h3>Children</h3>
            <div class="d-flex flex-wrap" *ngIf="students.length > 0">
              <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let s of students" (click)="viewStudent(s.ID)"> <fa-icon [icon]="faUser"></fa-icon>  {{s.Name}} {{s.Surname}}</button>
              <button class="m-0" mat-mini-fab color="accent" (click)="openSideNav(sideNavActions.NewStudent)"><fa-icon [icon]="faPlus"></fa-icon></button>
          </div> -->
    </div>
  </div>
  </div>