import { BreakpointObserver } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS, StepperOrientation } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faFrown, faSadTear, faSmile } from '@fortawesome/free-solid-svg-icons';
import { LearnerSurvey } from 'backend/interfaces/learner-survey.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-learner-survey',
  templateUrl: './learner-survey.component.html',
  styleUrls: ['./learner-survey.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false, showError: true},
    },
  ],
})
export class LearnerSurveyComponent implements OnInit {
  facilitiesForm: FormGroup;
  trainingMaterialForm: FormGroup;
  facilitatorForm: FormGroup;
  trainingAidsForm: FormGroup;
  learningActivitiesForm: FormGroup;
  courseTimingForm: FormGroup;
  ratingForm: FormGroup;
  faSmile = faSmile;
  faFrown = faFrown;
  faSad = faSadTear;
  @Input('enrollment') enrollment: any;
  stepperOrientation: Observable<StepperOrientation>;
  constructor(private fb: FormBuilder, private breakpointObserver: BreakpointObserver, private dataService: DataService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'))}

  ngOnInit(): void {
    this.facilitiesForm = this.fb.group({
      ventilation: ['', Validators.required],
      seating: ['', Validators.required],
      tables: ['', Validators.required],
      lighting: ['', Validators.required],
      accessibility: ['', Validators.required],
      parking: ['', Validators.required],
      acoustics: ['', Validators.required],
      toilets: ['', Validators.required]
    });
    this.trainingMaterialForm = this.fb.group({
      quality: ['', Validators.required],
      condition: ['', Validators.required],
      easeOfUse: ['', Validators.required],
      relevanceOfTraining: ['', Validators.required],
      sufficientSubjectMatter: ['', Validators.required],
      portable: ['', Validators.required]
    });
    this.facilitatorForm = this.fb.group({
      appearance: ['', Validators.required],
      clarityOfExplanation: ['', Validators.required],
      enthusiasm: ['', Validators.required],
      helpfulness: ['', Validators.required],
      knowledgeOfSubjectMatter: ['', Validators.required],
      preparation: ['', Validators.required]
    });
    this.trainingAidsForm = this.fb.group({
      relevance: ['', Validators.required],
      visibility: ['', Validators.required],
      clarifyingQualities: ['', Validators.required],
      quality: ['', Validators.required],
      availableWhenRequired: ['', Validators.required],
      accessToLearners: ['', Validators.required]
    });
    this.learningActivitiesForm = this.fb.group({
      relevance: ['', Validators.required],
      easyToPerform: ['', Validators.required],
      clarifyingQualities: ['', Validators.required],
      sufficientContent: ['', Validators.required],
      timeToCompleteSufficient: ['', Validators.required],
      levelOfComplication: ['', Validators.required]
    });
    this.courseTimingForm = this.fb.group({
      dailyTimes: ['', Validators.required],
      lengthOfCourse: ['', Validators.required],
      dayOfWeek: ['', Validators.required],
      duringTheMonth: ['', Validators.required],
      duringTheYear: ['', Validators.required]
    });
    this.ratingForm = this.fb.group({
      comments: [''],
      suggestions: [''],
      rating: ['', Validators.required]
    });
  }

  submit(): void{
    const result: LearnerSurvey = {
      facilities: this.facilitiesForm.value,
      trainingMaterial: this.trainingMaterialForm.value,
      facilitator: this.facilitatorForm.value,
      trainingAids: this.trainingAidsForm.value,
      learningActivities: this.learningActivitiesForm.value,
      courseTiming: this.courseTimingForm.value,
      comments: this.ratingForm.controls['comments'].value,
      suggestions: this.ratingForm.controls['suggestions'].value,
      overallRating: this.ratingForm.controls['rating'].value,
    } 
    console.log(result)
    this.dataService.saveLearnerSurvey(this.enrollment.id, result);

  }

}
