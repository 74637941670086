import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFile, faFilePdf, faImage, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { FileViewerDialogComponent } from '../file-viewer-dialog/file-viewer-dialog.component';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/admin/services/course.service';
import { Course } from 'backend/interfaces/course.interface';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Parent } from 'backend/interfaces/parent.interface';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-gdrive',
  templateUrl: './gdrive.component.html',
  styleUrls: ['./gdrive.component.scss']
})
export class GdriveComponent implements OnInit {

  constructor(private courseService: CourseService, private _snackBar: MatSnackBar, readonly dialog: MatDialog, private router: Router, private authService: AuthService, private dataService: DataService) { }
  @Input('context') context: string;
  loading = false;
  course: Course;
  user: Parent;
  files;
  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  faImage = faImage;
  faFile = faFile;
  faTrash = faTimes;
  faUser = faUser;
  isAdmin = false;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.isAdmin = this.authService.getUserRole() === 'admin';
    if(this.context==='user'){
      this.authService.$User.subscribe(async (s) => {
        if (s) {
          this.dataService.getUserDriveId(s.id).then(async res => {
            this.user = { ...s, DriveId: res };

            await this.courseService.getcourseFiles(this.user.DriveId).then(res => {
              this.files = res;
            }).catch(err => console.log('err: ', err));

          });
        }
      });
      }
    if(this.context==='course' || !this.context){
    this.courseService.activatedCourse$.subscribe(async s => {
      this.course = s;
    });
    await this.courseService.getcourseFiles(this.course.DriveId).then(res => {
      this.files = res;
    }).catch(err => console.log('err: ', err));
    }
    this.loading = false
  }

  createDriveFolder(){
    this.loading = true;
    this.courseService.createDriveFolder(this.course.id).then(async result => {
      this.course.DriveId = result.DriveId;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'course', this.course.id, 'files']));
      this.loading = false;
    });
  }

  createUserDriveFolder(){
    this.loading = true;
    this.dataService.createUserDriveFolder(this.user.id).then(async result => {
      this.user.DriveId = result.DriveId;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([this.isAdmin ? 'admin' : 'user', 'profile']));
      this.loading = false;
    });
  }

  async uploadFile(){
    let description = '';
    this.loading = true;
    this.courseService.uploadFile(this.course.DriveId, this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      await this.courseService.getcourseFiles(this.course.DriveId).then(res => {
        if(res.includes("not found"))
        {
          console.log(res)
        }else{
          this.files = res;
        }
      })
      this.loading = false;
    });
  }


  async uploadUserFile(){
    let description = '';
    this.loading = true;
    this.dataService.uploadFile(this.user.DriveId, this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      await this.dataService.getUserFiles(this.user.DriveId).then(res => {
        if(res.includes("not found"))
        {
          console.log(res)
        }else{
          this.files = res;
        }
      })
      this.loading = false;
    });
  }


async getFile(fileId: string, mimeType: string, fileName: string){
  this.loading = true;
  // document.getElementById('showcaseImage').setAttribute("src", '');
  // document.getElementById('showcaseFile').setAttribute("src", '');
  return this.courseService.getCourseFile(fileId, mimeType).then(res => {
    // if(mimeType.includes('image')){
    //   document.getElementById('showcaseImage').setAttribute("src", res);
    // }else{
      this.viewDriveFolder(fileName, mimeType, res, fileId)
      // document.getElementById('showcaseFile').setAttribute("src", res);
    // }
    this.selectedFile = {
      type: mimeType.includes('image') ? 'image' : 'file',
      src: res
    }
    this.loading = false;
    return res;
  }).catch(err => console.log(err))

}

async getUserFile(fileId: string, mimeType: string, fileName: string){
  this.loading = true;
  // document.getElementById('showcaseImage').setAttribute("src", '');
  // document.getElementById('showcaseFile').setAttribute("src", '');
  return this.dataService.getUserFile(fileId, mimeType).then(res => {
    // if(mimeType.includes('image')){
    //   document.getElementById('showcaseImage').setAttribute("src", res);
    // }else{
      this.viewDriveFolder(fileName, mimeType, res, fileId)
      // document.getElementById('showcaseFile').setAttribute("src", res);
    // }
    this.selectedFile = {
      type: mimeType.includes('image') ? 'image' : 'file',
      src: res
    }
    this.loading = false;
    return res;
  }).catch(err => console.log(err))

}
  
  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          reader.readAsDataURL(file)
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
  async viewDriveFolder(name, mimeType, file, fileId){
    // if(mimeType.includes('image')){
    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      data: {name, mimeType, file, fileId},
      width: '90%',
      maxWidth: '100vw',
      height: '90%',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  }
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    
      //decode base64 data
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
    
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
    
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
    
          var byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
      }
    
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }

    removeFile(id: string, name: string){
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        data: {ID: id, Name: name}
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.courseService.removeFile(id).then(res => {
            this.files = this.files.filter((f: any) => {
              console.log(f.id, ':', id)
              if(f.id !== id){
                return f
              }
          });
            this.openSnackBar('File removed successfuly');
          }) 
        } else {
          this.dialog.closeAll();
        }
      });
    }
  
}
