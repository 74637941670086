<p>{{question.title}}</p>
<form [formGroup]="questionForm" class="d-flex flex-column">
    <mat-form-field>
        <mat-label>
            Title
        </mat-label>
        <input matInput name="title" formControlName="title">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Body
        </mat-label>
        <input matInput name="body" formControlName="body">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Duration
        </mat-label>
        <input matInput name="duration" formControlName="duration">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Points
        </mat-label>
        <input matInput name="points" formControlName="points">
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Note
        </mat-label>
        <input matInput name="note" formControlName="note">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Answer Type</mat-label>
        <mat-select formControlName="answerType">
            <mat-option *ngFor="let type of answerTypes" [value]="type.text" >
              {{type.text}}
            </mat-option>
          </mat-select>
    </mat-form-field>
</form>