import { Injectable } from '@angular/core';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Group } from 'backend/interfaces/group.interface';
import { Parent } from 'backend/interfaces/parent.interface';
import { Course } from 'backend/interfaces/course.interface';
import { Answer } from 'backend/interfaces/answer.interface';
import { LearnerSurvey } from 'backend/interfaces/learner-survey.interface';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  services = new BehaviorSubject<Service[] | null>(null);
  services$ = this.services.asObservable();
  locations = new BehaviorSubject<Location[] | null>(null);
  locations$ = this.locations.asObservable();
  courses = new BehaviorSubject<any[] | null>(null);
  courses$ = this.courses.asObservable();
  practitioners = new BehaviorSubject<Practitioner[] | null>(null);
  practitioners$ = this.practitioners.asObservable();
  groups = new BehaviorSubject<Group[] | null>(null);
  groups$ = this.groups.asObservable();
  parent = new BehaviorSubject<Parent | null>(null);
  parent$ = this.parent.asObservable();
  enrollments = new BehaviorSubject<any[] | null>(null);
  enrollments$ = this.enrollments.asObservable();
  activatedDrive = new BehaviorSubject<any[]>(null);
  activatedDrive$ = this.activatedDrive.asObservable();
  constructor() {
    // this.getServices();
    // this.getLocations();
  }

  getServices(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getServices', { displayLimit, page }).then((result) => {
      this.services.next(result);
    });
  }

  getGroups(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getGroups', { displayLimit, page }).then((result) => {
      this.groups.next(result);
    });
  }

  getCourses(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getCourses', { displayLimit, page }).then((result) => {
      this.courses.next(result);
    });
  }

  getLocations(): Promise<Location[]> {
    return Parse.Cloud.run('getLocations').then((result) => {
      this.locations.next(result);
    });
  }

  getPractitioners(): Promise<Practitioner[]> {
    return Parse.Cloud.run('getPractitioners').then((result) => {
      this.practitioners.next(result);
    });
  }

  getCalendarBusy(from: string, to: string): Promise<any>{
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getAvailableTimeSlots(day: number, month: number, year: number, duration: number): Promise<any>{
    return Parse.Cloud.run('getAvailableTimeSlots', {day, month, year, duration})
  }

  getAvailableTimes(duration: number, day: number, month: number, year: number, location: string, preferredPractitioner: string): Promise<any>{
    return Parse.Cloud.run('getAvailableTimes', {duration, day, month, year, location, preferredPractitioner})
  }

  getAlbums(id: string): Promise<any[]> {
    return Parse.Cloud.run('getAlbumList', { id }).then((result) => {
      this.courses.next(result);
    });
  }

  saveUser(user: Parent):Promise<any>{
    return Parse.Cloud.run('updateUserProfile', { user })
  }

  followGroup(group: Group): Promise<Group> {
    return Parse.Cloud.run('followGroup', { group }).then((result) => {
      return result;
    });
  }

  unfollowGroup(group: Group): Promise<Group> {
    return Parse.Cloud.run('unfollowGroup', { group }).then((result) => {
      return result;
    });
  }

  getCourseById(id: string): Promise<Course> {    
    return Parse.Cloud.run('getCourseById', { id })
  }

  createUserDriveFolder(userId: string): Promise<any>{
    return Parse.Cloud.run('createUserDriveFolder', { userId })
  }

  saveEnrollment(userId: string, key: string, answers: Answer[], status: string){
    return Parse.Cloud.run('saveEnrollment', { userId, key, answers, status })
  }

  saveAssessment(userId: string, key: string, answers: Answer[], status: string, section: string){
    return Parse.Cloud.run('saveAssessment', { userId, key, answers, status, section })
  }

  submitAssessment(userId: string, key: string, answers: Answer[], status: string, section: string){
    return Parse.Cloud.run('submitAssessment', { userId, key, answers, status, section })
  }

  saveLearnerSurvey(key: string, answers: LearnerSurvey){
    return Parse.Cloud.run('saveLearnerSurvey', { key, answers})
  }

  getEnrollmentsByUserId(id: string): Promise<Course> {    
    return Parse.Cloud.run('getEnrollmentsByUserId', { id }).then(res => {
      this.enrollments.next(res);
    })
  }

  getUserDriveId(userId: string): Promise<string>{
    return Parse.Cloud.run('getUserDriveId', { userId })
  }

  getcourseFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  getUserFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
  }

  uploadUserId(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadUserId', { id, uploadedFile, description })
  }

  getUserIdDoc(id: string): Promise<any>{
    return Parse.Cloud.run('getUserIdDoc', { id })
  }

  getUserFile(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileById', { id, mimeType })
  }

}
