<div mat-dialog-title class="d-flex">
    <h1>{{ title }}</h1>
    <div class="spacer"></div>
  </div>
  
  <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
  <div mat-dialog-content class="text-center d-flex justify-content-between flex-column align-items-center">
    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #fileInput
      style="display: none"
    />
    <br />
   
    <div class="profile-pic-initials lg flex-column link" (click)="uploadFile()">
        <h3 class="absolute text-primary">Click to upload</h3>
      <img
        class="text-center margin-auto img-fluid"
        src="./../../../assets/placeholder_class.png"
        #webcamImageDisplay
        alt="webcam image"
      />
    </div>
  </div>
  <div mat-dialog-actions class="d-flex mt-4">
    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #recognitionFileInput
      style="display: none"
    />
    <button mat-button (click)="onNoClick()">Cancel</button>
    <div class="spacer"></div>
    <button
      mat-raised-button
      [disabled]="(!webcamImage && !uploadedImage) || saving"
      color="primary"
      (click)="saveProfilePicture()"
      cdkFocusInitial
    >
      {{saving ? 'Saving' : 'Upload'}}
    </button>
  </div>
  