import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Question } from 'backend/interfaces/question.interface';

@Component({
  selector: 'app-course-question',
  templateUrl: './course-question.component.html',
  styleUrls: ['./course-question.component.scss']
})
export class CourseQuestionComponent implements OnInit {
  @Input('question') question: Question = {
    id: '',
    title: '',
    duration: '',
    answer: '',
    answerType: '',
    points: '',
    note: '',
    body: ''
  };
  @Output('updateTestForm') updateTestForm: EventEmitter<any> = new EventEmitter;
  questionForm: FormGroup;
  answerType = 'input';
  answerTypes = [
    {
      icon: 'drag_handle',
      text: 'Short text',
      type: 'input'
    },
    {
      icon: 'reorder',
      text: 'Long text',
      type: 'textArea'
    }
  ]
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      title: [this.question.title],
      duration: [this.question.duration],
      answer: [this.question.answer],
      answerType: [this.question.answerType],
      points: [this.question.points],
      note: [this.question.note],
      body: [this.question.body]
    });

    this.questionForm.valueChanges.subscribe(res => {
      this.updateTestForm.emit(res)
    })
  }

}
