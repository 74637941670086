import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCamera, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { StudentService } from 'src/app/admin/services/student.service';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Parent } from 'backend/interfaces/parent.interface';
import { CourseService } from 'src/app/admin/services/course.service';

interface DialogData {
  title: string;
  studentId?: string;
  parentId?: string
  result: boolean;
  currentPicture: string;
  practitionerId?: string;
}

interface Detection {
    angry: number;
    disgusted: number;
    fearful: number;
    happy: number;
    neutral: number;
    sad: number;
    surprised:number;
}
@Component({
  selector: 'app-profile-picture-dialog',
  templateUrl: './profile-picture-dialog.component.html',
  styleUrls: ['./profile-picture-dialog.component.scss']
})
export class ProfilePictureDialogComponent implements OnInit, AfterViewInit {

  title;
  question;
  webcamImage: WebcamImage = null;
  uploadedImage: {name: string, source: string | ArrayBuffer, type?: string};
  showCamera = false;
  faCamera = faCamera;
  faUpload = faUpload;
  faUser = faUser;
  detectionStream: Detection = {
    angry: 0,
    disgusted: 0,
    fearful: 0,
    happy: 0,
    neutral: 0,
    sad: 0,
    surprised:0 
  };
  saving = false;
  recognitionImage: {name: string, source: string | ArrayBuffer, type?: string};
  parent: Parent;
  chartData = 10;
  @ViewChild('webcamImageDisplay') webcamImageDisplay: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('dataInput') dataInput: ElementRef;
  // @ViewChild('recognitionFileInput') recognitionFileInput: ElementRef;
  constructor(public dialogRef: MatDialogRef<ProfilePictureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private studentService: StudentService, private practitionerService: PractitionerService, private courseService: CourseService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.title = this.data.title;
    // this.courseService.activatedCourse$.subscribe(res => this.parent= res);
  }

  ngAfterViewInit(){
    this.webcamImageDisplay.nativeElement.src = this.data.currentPicture
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showThumb(result: {image: WebcamImage, detection: any}): void {

    // for (const key in result.detection.expressions)
    // {
    //    const value = parseFloat(result.detection.expressions[key]).toPrecision(2);
    //    if(key !== 'asSortedArray')
    //     this.detectionStream.push({key, val: value});
    // }
    this.webcamImage = result.image;
    if(this.webcamImageDisplay)
      this.webcamImageDisplay.nativeElement.src = result.image.imageAsDataUrl;
    this.recognitionImage = {
      source: result.image.imageAsBase64,
      name: this.parent.Name +'_'+this.parent.Surname,
      type: 'image/jpeg',
    }
    this.uploadedImage = {
      source: result.image.imageAsDataUrl,
      name: this.parent.Name +'_'+this.parent.Surname,
      type: 'image/jpeg',
    }
    this.toggleCamera();
  }

  async onFileSelected(event): Promise<void>{

    let reader = new FileReader();

    this.webcamImage = null;
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.webcamImageDisplay.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name,
            type: file.type,
          }
        }
      };
    }
  }

  toggleCamera(){
    this.showCamera = !this.showCamera;
  }

  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  // uploadRecognitionFile(){
  //   this.recognitionFileInput.nativeElement.value = null;
  //   this.recognitionFileInput.nativeElement.click();
  // }

  async saveProfilePicture(): Promise<void>{
    const imageToUpload = this.uploadedImage;
    this.saving = true;
    // if(this.data.practitionerId){
    //   this.practitionerService.savePractitionerProfilePicture(this.data.practitionerId, imageToUpload).then(res => {
    //     this.dialogRef.close(this.webcamImageDisplay.nativeElement.src);
    //     this.openSnackBar("Photo successfuly added.");
    //   }).catch(err => {
    //     console.log(err);
    //   });
    // }else if(this.data.parentId){
      this.courseService.saveProfilePicture(this.data.parentId, imageToUpload).then(res => {
        this.dialogRef.close(this.webcamImageDisplay.nativeElement.src);
        this.openSnackBar("Photo successfuly added.");
        this.saving = false;
      }).catch(err => {
        console.log(err);
      });
    // }else{
    //   this.studentService.saveProfilePicture(this.data.studentId, imageToUpload).then(res => {
    //     this.dialogRef.close(this.webcamImageDisplay.nativeElement.src);
    //     this.openSnackBar("Photo successfuly added.");
    //   }).catch(err => {
    //     console.log(err);
    //   });
    // }
  }


  // async uploadToBucket(): Promise<void>{
  //   this.courseService.uploadPictureToBucket(this.uploadedImage, this.parent.Name, 'dfaces', this.parent.ID).then(async res => {
  //     this.openSnackBar(res.toString());
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // }

  // async recognise(): Promise<void>{

  //   // let reader = new FileReader();

  //   // this.webcamImage = null;
  //   // if (event.target.files && event.target.files.length) {
  //   //   const file: File = event.target.files[0];
  //   //   reader.readAsDataURL(file);
  //   //   reader.onload = () => {
  //   //     if (file.type.includes('image')) {
  //   //       this.webcamImageDisplay.nativeElement.src = reader.result;
  //   //       this.uploadedImage = {
  //   //         source: reader.result as any,
  //   //         name: file.name,
  //   //         type: file.type,
  //   //       }

  //         this.parentService.uploadPictureToBucket(this.uploadedImage, this.uploadedImage.name, 'drecognitionrequests').then(async res => {
  //           this.openSnackBar(res.toString());
  //           this.parentService.authenticate(this.uploadedImage).then(async res => {
  //             this.openSnackBar(res.Message + '! ' + res.firstName + ' ' + res.lastName);
  //           }).catch(err => {
  //             console.log(err);
  //           });
  //         }).catch(err => {
  //           console.log(err);
  //         });
  //   //     }
  //   //   };
  //   // }
  // }

  // async recognise(): Promise<void>{
  //        await this.parentService.uploadPictureToBucket(this.uploadedImage, this.uploadedImage.name, 'drecognitionrequests','').then(async res => {
  //           this.openSnackBar('Identifying person...');
  //           this.parentService.authenticate(this.uploadedImage).then(async res => {
  //             this.openSnackBar(res.Message + '! Recognised as ' + res.firstName + ' ' + res.lastName);
  //             this.dialogRef.close();
  //           }).catch(err => {
  //             console.log(err);
  //           });
  //         }).catch(err => {
  //           console.log(err);
  //         });
  // }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  updateChartData(event: Detection){
    // console.log({...event})
    this.detectionStream = {...event};
    // for (const key in event.detection.expressions)
    // {
    //    const value = parseFloat(event.detection.expressions[key]).toPrecision(2);
    //    if(key !== 'asSortedArray')
    //     this.detectionStream.push({key, val: value});
    // }
    this.chartData = event.happy;
  }
  
}
