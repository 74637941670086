import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Parse } from 'parse';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { StudentService } from 'src/app/admin/services/student.service';
import { Student } from 'backend/interfaces/student.interface';
import { AuthService } from '../auth.service';
import { Parent } from 'backend/interfaces/parent.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  passwordVisible: boolean = false;
  faPhoneVolume = faPhoneVolume;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      id: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { 
      return this.loginForm.controls; 
  }

  clearButton() {
    this.loading = false;
  }

  async onSubmit(): Promise<any> {
    this.loading = true;
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    const parent: Parent = {
      Name: this.f.name.value,
      Surname: this.f.surname.value,
      Mobile: this.f.mobile.value,
      Email: this.f.email.value.toLowerCase(),
      ID: this.f.id.value
    }
    const password = this.f.password.value;
    // console.log(parent);
    this.authService.signup(parent, password).then(res => {
      this.openSnackBar('Success, please continue by signing in.')
      this.router.navigate(['login']);
    }).catch(err => alert(err));
    
    // let user = new Parse.User();
    // user.set("Name", this.f.name.value);
    // user.set("Surname", this.f.surname.value);
    // user.set("Mobile", this.f.mobile.value);
    // user.set("username", this.f.email.value);
    // user.set("password", this.f.password.value);
    // user.set("email", this.f.email.value);


    // try {
    //   await user.signUp();
    //   this.router.navigate(['/']);
    // } catch (error) {
    //   // Show the error message somewhere and let the user try again.
    //   alert("Error: " + error.code + " " + error.message);
    // }
  }

    togglePassword(show) {
        this.passwordVisible = show;
    }

    openSnackBar(message: string) {
      this._snackBar.open(message, 'OK', {
        duration: 3000
      });
    }

    goTo(route: string){
      this.router.navigate([route]);
  }
}
