<h1>Learner Survey</h1>
<mat-stepper
  #stepper
  [orientation]="(stepperOrientation | async)!"
  class="bg-light"
>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="facilitiesForm"
    [completed]="facilitiesForm?.valid"
    [stepControl]="facilitiesForm"
  >
    <!-- <h4>facilities</h4> -->
    <ng-template matStepLabel>Facilities</ng-template>
    <form [formGroup]="facilitiesForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="ventilation"
          aria-label="Ventilation/Temperature"
        >
          <mat-label class="flex-1">Ventilation/Temperature</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="seating"
          aria-label="Seating"
        >
          <mat-label class="flex-1">Seating</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="tables"
          aria-label="Tables / Desks"
        >
          <mat-label class="flex-1">Tables / Desks</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="lighting"
          aria-label="Lighting"
        >
          <mat-label class="flex-1">Lighting</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="accessibility"
          aria-label="accessibility"
        >
          <mat-label class="flex-1">Accessibility (Location in Premises / suburb)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="parking"
          aria-label="Parking"
        >
          <mat-label class="flex-1">Parking</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="acoustics"
          aria-label="Acoustics"
        >
          <mat-label class="flex-1">Acoustics (Can facilitator be heard?)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="toilets"
          aria-label="Toilets"
        >
          <mat-label class="flex-1">Toilets</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
    <div class="d-flex justify-content-between">
      <button mat-stroked-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="trainingMaterialForm"
    [completed]="trainingMaterialForm?.valid"
    [stepControl]="trainingMaterialForm"
  >
    <!-- <h4>trainingMaterial</h4> -->
    <ng-template matStepLabel>Training Material</ng-template>
    <form [formGroup]="trainingMaterialForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="quality"
          aria-label="Quality"
        >
          <mat-label class="flex-1">Quality</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="condition"
          aria-label="Condition"
        >
          <mat-label class="flex-1">Condition</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="easeOfUse"
          aria-label="Ease of Use"
        >
          <mat-label class="flex-1">Ease of Use</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="relevanceOfTraining"
          aria-label="Relevance of Training"
        >
          <mat-label class="flex-1">Relevance of Training</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="sufficientSubjectMatter"
          aria-label="Sufficient subject matter"
        >
          <mat-label class="flex-1">Sufficient subject matter</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="portable"
          aria-label="Portable"
        >
          <mat-label class="flex-1">Portable (Can I use it in the workplace?)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="facilitatorForm"
    [completed]="facilitatorForm?.valid"
    [stepControl]="facilitatorForm"
  >
    <!-- <h4>facilitator</h4> -->
    <ng-template matStepLabel>Facilitator</ng-template>
    <form [formGroup]="facilitatorForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="appearance"
          aria-label="Appearance"
        >
          <mat-label class="flex-1">Appearance</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="clarityOfExplanation"
          aria-label="Clarity of explanation"
        >
          <mat-label class="flex-1">Clarity of explanation</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="enthusiasm"
          aria-label="Enthusiasm"
        >
          <mat-label class="flex-1">Enthusiasm</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="helpfulness"
          aria-label="Helpfulness"
        >
          <mat-label class="flex-1">Helpfulness</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="knowledgeOfSubjectMatter"
          aria-label="Knowledge of subject matter"
        >
          <mat-label class="flex-1">Knowledge of subject matter</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="preparation"
          aria-label="Preparation"
        >
          <mat-label class="flex-1">Preparation</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="trainingAidsForm"
    [completed]="trainingAidsForm?.valid"
    [stepControl]="trainingAidsForm"
  >
    <!-- <h4>trainingAids</h4> -->
    <ng-template matStepLabel>Training Aids</ng-template>
    <form [formGroup]="trainingAidsForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="relevance"
          aria-label="Relevance"
        >
          <mat-label class="flex-1">Relevance</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="visibility"
          aria-label="Visibility"
        >
          <mat-label class="flex-1">Visibility</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="clarifyingQualities"
          aria-label="Clarifying Qualities"
        >
          <mat-label class="flex-1">Clarifying Qualities (Does it make the meaning clear?)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="quality"
          aria-label="Quality / Condition"
        >
          <mat-label class="flex-1">Quality / Condition</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="availableWhenRequired"
          aria-label="Available when Required"
        >
          <mat-label class="flex-1">Available when Required</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="accessToLearners"
          aria-label="AccessToLearners"
        >
          <mat-label class="flex-1">Access to Learners</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="learningActivitiesForm"
    [completed]="learningActivitiesForm?.valid"
    [stepControl]="learningActivitiesForm"
  >
    <!-- <h4>learningActivities</h4> -->
    <ng-template matStepLabel>Learning Activities</ng-template>
    <form [formGroup]="learningActivitiesForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="relevance"
          aria-label="Relevance"
        >
          <mat-label class="flex-1">Relevance</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="easyToPerform"
          aria-label="Easy to perform"
        >
          <mat-label class="flex-1">Easy to perform</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="clarifyingQualities"
          aria-label="clarifyingQualities"
        >
          <mat-label class="flex-1">Clarifying qualities (Does it make the meaning clear?)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="sufficientContent"
          aria-label="Sufficient content"
        >
          <mat-label class="flex-1">Sufficient content</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="timeToCompleteSufficient"
          aria-label="Time to complete sufficient"
        >
          <mat-label class="flex-1">Time to complete sufficient</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="levelOfComplication"
          aria-label="Level of complication"
        >
          <mat-label class="flex-1">Level of complication</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="courseTimingForm"
    [completed]="courseTimingForm?.valid"
    [stepControl]="courseTimingForm"
  >
    <!-- <h4>courseTiming</h4> -->
    <ng-template matStepLabel>Course Timing</ng-template>
    <form [formGroup]="courseTimingForm">
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="dailyTimes"
          aria-label="Daily times"
        >
          <mat-label class="flex-1">Daily times (Start, teas, lunch, finish)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="lengthOfCourse"
          aria-label="Length of course"
        >
          <mat-label class="flex-1">Length of course</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="dayOfWeek"
          aria-label="Day of week"
        >
          <mat-label class="flex-1">Day of week (Mon, Tues, Wed, Thurs, Fri, Sat, Sun)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="duringTheMonth"
          aria-label="During the month"
        >
          <mat-label class="flex-1">During the month (Beginning, Middle, End)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="p-2 bg-grey m-2">
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="duringTheYear"
          aria-label="During the year"
        >
          <mat-label class="flex-1">During the year (Beginning, Middle, End)</mat-label>
          <mat-radio-button class="radio-button flex-1" value="GOOD">
            GOOD
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="FAIR">
            FAIR
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="POOR">
            POOR
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
        <button mat-stroked-button matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step
    class="p-3 m-3 bg-light border-primary"
    *ngIf="ratingForm"
    [completed]="ratingForm?.valid"
    [stepControl]="ratingForm"
  >
    <!-- <h4>rating</h4> -->
    <ng-template matStepLabel>Rating</ng-template>
    <form [formGroup]="ratingForm" class="d-flex flex-column">
        <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput placeholder="Comments" formControlName="comments"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Suggestions / Improvements</mat-label>
            <textarea matInput placeholder="Suggestions" formControlName="suggestions"></textarea>
        </mat-form-field>
      <div class="p-2 bg-grey m-2">
        <p>Overall Course Rating (1=poor, 10=Good)</p>
        <mat-radio-group
          class="d-flex w-100 flex-wrap align-items-center"
          formControlName="rating"
          aria-label="rating"
        >
          <mat-radio-button class="radio-button flex-1" value="1">
            1
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="2">
            2
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="3">
            3
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="4">
            4
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="5">
            5
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="6">
            6
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="7">
            7
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="8">
            8
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="9">
            9
          </mat-radio-button>
          <mat-radio-button class="radio-button flex-1" value="10">
            10
          </mat-radio-button>
        </mat-radio-group>
      </div>
      </form>
      <div class="d-flex justify-content-between">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-raised-button color="primary" (click)="submit()">Submit</button>
    </div>
  </mat-step>
</mat-stepper>
