import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface DialogData {
  title: string;
  test: string;
}

@Component({
  selector: 'app-submision-complete-dialog',
  templateUrl: './submision-complete-dialog.component.html',
  styleUrls: ['./submision-complete-dialog.component.scss']
})
export class SubmisionCompleteDialogComponent implements OnInit {
  title;
  test;
  faCheck = faCheck
  constructor(public dialogRef: MatDialogRef<SubmisionCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    // console.log(this.data)
    this.title = this.data.title;
    this.test = this.data.test;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
