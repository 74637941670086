  import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CourseSearchCriteria } from 'backend/interfaces/course.interface';
import { CourseService } from 'src/app/admin/services/course.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';

@Component({
  selector: 'app-search-course',
  templateUrl: './search-course.component.html',
  styleUrls: ['./search-course.component.scss']
})
export class SearchCourseComponent implements OnInit {
  loading = false;
  searchParentForm: UntypedFormGroup;
  noResults = false;
  faSearch = faSearch;
  searchPhrase: CourseSearchCriteria;
  constructor(private fb: UntypedFormBuilder, private courseService: CourseService, private sideNavService: SideNavService, private router: Router) { }

  ngOnInit(): void {
      this.searchParentForm = this.fb.group(
        {
          name: ['']
      });
      this.courseService.courseSearch$.subscribe(r => {
        this.searchPhrase = r;
        this.searchParentForm.patchValue({...r})
      })
  }

  search(){
    this.noResults = false;
    this.loading = true;
    this.courseService.searchCourse(this.searchParentForm.value).then(res => {
      if(res.courses.length > 0){
        this.courseService.courseSearch.next(this.searchParentForm.value);
        this.courseService.courses.next(res.parents);
        this.courseService.courseCount.next(res.count);
        this.router.navigate(['admin','courses']);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }

  async clearSearch(){
    this.courseService.courseSearch.next({name:  ''});
    this.loading = true;
    await this.courseService.getCourseCount();
    await this.courseService.getCourses(10, 0);
    this.loading = false;
  }
}
